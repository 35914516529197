import styled from 'styled-components';

export const Nav = styled.div`
  display: flex;
  padding-top: 15px;
	padding-bottom: 15px;
	justify-content: space-between;
`;

export const NavButtons = styled.button`
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'}; 
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1.8em;
  background: ${({ theme }) => theme.button.navigation.background};
  font-size: 25px;
  color: ${({ theme }) => theme.button.navigation.color};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({ enabled }) => enabled ? `
    outline: none;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
    cursor: pointer;

    &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }

    &:focus {
        outline: none;
    }`
    :
    `background: #C4C8CC;`
  }
`;

export const ArrowLeft = styled.span`
  :before {
    font-family: efl_iconfont;
    content: '\\4c'
  }
`;

export const ArrowRight = styled.span`
  :before {
    font-family: efl_iconfont;
    content: '\\52'
  }
`;

export const Header = styled.div`
  display: flex;
	justify-content: ${({ space }) => space ? 'space-between' : 'flex-end'};
	padding: 15px 15px;
  @media (max-width: 350px) {
  	padding: 5px 0px;
  }
`;

export const Overlay = styled.div`
  opacity: ${({ show }) => show ? 1 : 0};
  visibility: ${({ show }) => show ? 'visible' : 'hidden'};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  background: rgba(255, 255, 255, 0.7);
  overflow-y: auto;
  padding: 0 20px;
  z-index: 1000;
  transition-delay: 0.25s;
  transition: all 0.5s ease;
`;