'use strict';
import React, { useState } from 'react';
import _get from 'lodash.get';

const swapScreen = (array, j, i) => {

  let temp;

  temp = array[j];
  array[j] = array[i];
  array[i] = temp;
};

const shuffleArray = array => {

  let array_length = array.length;
  let random_number;

  // modified fisher-yates shuffle
  for (let i = array_length - 1; i >= 0; i--) {
    random_number = Math.floor(Math.random() * i + 1);
    swapScreen(array, random_number, i);
  }

  return array;
};


export const useRandomScreen = (module) => {
  const [randomized, setIsRandomized] = useState(false);

  let screens = module.screens;

  // check if there are screens to be randomized
  if (module.randomized && module.randomized.length > 0 && !randomized) {

    /**
     * LOGIC:
     * 1. create an empty array for storage (storage_array)
     * 2. for each key in randomized, check screens in module.screens with key: <randomized_key>
     * 3. if match, push screen to storage_array then replace its place on the screen array with <randomized_key>
     * 4. shuffle screens from storage_array then return it back to screen array, replacing indices with <randomized_key> value
     */

    const keys = module.randomized;
    const keys_length = keys.length;
    const screens_length = screens.length;
    let storage_array = [];

    for (let i = 0; i < keys_length; i++) {
      for (let j = 0; j < screens_length; j++) {
        if (screens[j].key === keys[i]) {
          storage_array.push(screens[j]);
          screens[j] = keys[i];
        }
      }

      const shuffled_array = shuffleArray(storage_array);

      //  now return elements of shuffled_array to screens
      let counter = 0;

      for (let j = 0; j < screens_length; j++) {
        if (screens[j] === keys[i]) {
          screens[j] = shuffled_array[counter];
          counter++;
        }
      }

      // clear storage_array for next key in randomize
      storage_array = [];
      setIsRandomized(true);
    }
  }

  return screens;
};

export const screenManager = (common = {}, screens = [], activeScreen) => {
  const screen = screens[activeScreen];

  if (!screen) return {};
  const _common = common[screen.key] || {};

  return {
    ..._common,
    ...screen
  }
};

export const nextScreenChecker = (target, active, screens, observations, skip_keys = {}) => {
  const to_skips = Object.keys(skip_keys);
  let newActiveScreen = active + target;
  const targetNewScreen = screens[newActiveScreen];

  const modules = Object.keys(observations);
  if (targetNewScreen) {
    // Check each observation key in skip_keys
    to_skips.forEach(skip_key => {
      // Iterate through the observations per module
      modules.forEach(module => {
        const module_observations = Object.keys(_get(observations, `${module}`, {}));
        // If the skip key is included in the observation already
        // check if it is one of the target value
        if (module_observations.includes(skip_key)) {
          const value = observations[module][skip_key]?.value;
          if (skip_keys[skip_key]?.values.includes('value')) {
            skip_keys[skip_key].values = skip_keys[skip_key].values.map((data) => data.replace("value", value || (value && value < 80) ? value : ""))
          }
          if (skip_keys[skip_key].values.includes(value) || skip_keys[skip_key].values.includes(`${value}`)) {
            // Now check if the target new screen is included in the screens to skipped
            if (skip_keys[skip_key].targets.includes(targetNewScreen.observation)) {
              // If yes, then go ahead and check the next screens, until we found the right screen to display
              newActiveScreen = nextScreenChecker(target, active + target, screens, observations, skip_keys)
            }
          }
        }
      });
    });
  }

  return newActiveScreen
};

let lastAnswer = {};
export const observationState = (screen, observations, answer) => {
  let newObs = {};
  if (!observations[screen.module]) {
    newObs = {
      ...observations,
      [screen.module]: {
        [screen.observation]: answer
      }
    };
  } else {
    const module_observations = observations[screen.module];

    const newModuleObs = {
      ...module_observations,
      [screen.observation]: answer 
    };

    newObs = {
      ...observations,
      [screen.module]: {
        ...newModuleObs
      }
    }
  }

  lastAnswer = answer;
  return newObs
};
