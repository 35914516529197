import styled from 'styled-components';

export const Img = styled.div`
display: flex;
position:relative;
justify-content: center;
flex-direction:column;
margin: 0px 6px;
background:rgb(240, 243, 247);
padding:.5rem;
@media (max-width:575px) and (min-width:320px){
    width: 22%;
    margin: 0px auto;
    .labelFont{
        font-size:10px;
    }
}
`;

export const ImgWraper = styled.div`
display:flex;
justify-content:space-between;
margin:10px auto;
max-width:450px;
p{
    margin:0px;
}
img{
    width: 100%;
    margin-top:.75rem;
}@media (max-width:767px) and (min-width:320px){
    max-width:100%;
}`;

export const Checkbox = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
`;
export const Span = styled.span`
display: inline-block;
  min-width: 1.5em;
  max-width: 1.5em;
  height: 1.5em;
  border: .125em solid ${({ theme }) => theme.colors.primary};
  border-radius: .8125em;
  background: #FFF;
  vertical-align: middle;
  text-align: center;
  line-height: 0;
  margin:0 .25em;
  ${({ selected, theme }) => selected && `
    span {
      display: inline-block;
      width: 1em;
      height: 1em;
      border-radius: .5em;
      background: ${theme.colors.primary};
      margin-top: .125em;
      @media (max-width:767px) and (min-width:320px){
        width: .75em;
        height: .75em;
        margin-top: .15em;
      }
    }
  `}
  @media (max-width:767px) and (min-width:320px){
    min-width: 1.25em;
  max-width: 1.25em;
  height: 1.25em;
}
`;


