import styled from 'styled-components';

export const CenteredText  = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const HintText = styled.div`
  background-color: #FFF2D4;
  color: #ED9E00;
  width: 100%;
  padding: 15px 20px;
  margin-bottom: .625em;
`;


