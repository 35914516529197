import styled, { createGlobalStyle } from 'styled-components'

import font_woff from '../fonts/efl-iconfont.woff';
import font_ttf from '../fonts/efl-iconfont.ttf';

export const GlobalBaseStyle = createGlobalStyle`
  @font-face {
    font-family: 'efl_iconfont';
    src: url(${font_woff}) format('woff'),
    url(${font_ttf}) format('ttf');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-weight: 400;
    line-height: 1.429;
    color: rgba(0,0,0,.87);
    -youbkit-touch-callout: none;
    -youbkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .moduleTitle {
      font-size: 24px;
      margin: 20px auto;
      
      @media (max-width: 768px) {
        text-align: center;
        margin-bottom: 0px;
      }
  }
  .moduleSubTitle {
      font-size: 20px;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 25px;
      }
  }
  .moduleText {
      font-size: 24px;
  }
  .formLabel {
      display: block;
  }
  .textMedium {
      font-weight: 600;
  }
  .questionText {
      margin-bottom: 30px;
      text-align: center;
  }
  .imageCont {
      margin: 20px 0;
      text-align: center;
  
      img {
          max-width: 100%;
      }
  }
  .helperInstruction {}
  .withHandPointer:after {
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      background: url('../../assets/images/hand.svg') center center no-repeat;
  }
  .textInput {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #D1D3D4;
      background-image: none !important;
  
      &:focus {
          outline: 0;
          box-shadow: none !important;
      }
  }
  
  .primaryBtn,
  .primaryBtn:hover {
      border: 0;
      outline: none;
      color: #fff;
  }
  .primaryBg {
      background: #BD1E6F;
      color: #fff;
  }
  .btnRaised {
      outline: none;
      box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
      cursor: pointer;
  
      &:active,
      &.active {
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
          border: 0;
      }
  
      &:focus {
          outline: none;
      }
  }
  .centerContent {
      margin: 0 auto;
  
      @media (min-width: 720px) {
          width: 66.67%;
      }
  }
`;

export const RaisedButton = styled.button`
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &:active,
  &.active {
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
      border: 0;
  }

  &:focus {
      outline: none;
  }
`;

export const CenterContent = styled.div`
  ${({ center }) => center && `
    margin: 0 auto;
    width: 66.67%;
    @media (min-width: 320px) and (max-width: 480px) {
      margin: 5px auto;
      border-radius: 4px;
      width: 100%;
    }
  `}
`;

export const Question = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  text-align: ${({ alignment }) => alignment};
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 2px;
}
`;

export const Hint = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  font-size: ${({ render_as }) => render_as === 'title' ? '18px' : '14px'};
  margin-bottom: 25px;
  text-align: ${({ alignment }) => alignment};
  @media (min-width:320px) and (max-width:427px){
  font-size: ${({ render_as, fontSize }) => render_as === 'title' ? '18px' : fontSize || '14px'};
  }
`;

export const Instruction = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  font-size: 18px;
  margin-bottom: 10px;
  text-align: ${({ alignment }) => alignment};
`;

export const Warning = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  margin-top: .25rem;

  width: 100%;
  margin-top: .25rem;

  color: #dc3545;
  
  padding: ${({ size }) => size === 'large' ? '15px' : '4px 15px'};
  font-size: ${({ size }) => size === 'large' ? '90%' : '80%'};
  
  margin-top: 0px;
  margin-bottom: 5px;
  background-color: #FFF2D4;
  color: #ED9E00;
  width: 100%;
  
  span:before {
    font-family: efl_iconfont;
    content: '\\61';
    font-style: normal;
    font-weight: 400;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
  }
`;

export const FormLabel = styled.label`
  display: block;
  text-align: ${({ alignment }) => alignment};
  font-size: 18px;
  margin-bottom: 10px;
`;