
import React, {useState, useEffect, useRef} from 'react';

const props = {
  digit_span: {
    secret_length: 4
  }
}

const convertKeywords = (keyword, params, fn) => {
  switch (keyword) {
    case '${ds_random_secret}':
      if (params.demo) {
        return String('35');
      } else {
        const secret = String(_getRandomSecret());

        props[module].secret = secret;
        fn.setSecret(secret);
        return secret;
      }
      break;
    default:
      return keyword;
      break;
  }
}

const _getSecretLength = (observations, secrets) => {
  // if no secret and answer, this is the first round
  if (!observations && !secrets) return 4;
  if (!observations[module] && !secrets[module]) return 4;

  const obs_keys = Object.keys(observations[module]);
  const sec_keys = Object.keys(secrets[module]);
  const last_observation_key = obs_keys[obs_keys.length -1];
  const last_answer_key = sec_keys[sec_keys.length -1];

  let _secret_len = 0;
  const answer = observations[module][last_observation_key];
  const secret = secrets[module][last_answer_key];
  const secret_length = props[module].secret_length;

  const is_valid = parseInt(answer) === parseInt(secret);

  if (is_valid) { _secret_len = secret_length + 2; }
  else { _secret_len = secret_length  - 1; }

  if (_secret_len < 1) _secret_len = 1;

  props[module].secret_length = _secret_len;
  return _secret_len;
}

const _getRandomSecret = (observations, secrets) => {
  const secret_len = _getSecretLength(observations, secrets);
  const secret_offset = Math.pow(10, secret_len - 1);

  return Math.floor(secret_offset + Math.random() * (secret_offset * 9));
}

export const useDigitSpan = ({ observations, observation, meta, module }) => {
  const [secrets, setSecrets] = useState({});

  useEffect(() => {
    if (meta.generate_secret || meta.demo) {
      if (!observations[module] || !Object.keys(observations[module]).length) {
        observations[module] = {};
      }

      const new_secret = { ...secrets };

      if (!new_secret[module]) {
        new_secret[module] = {}
      }

      new_secret[module][observation] = _getRandomSecret(observations, secrets);
      setSecrets(new_secret);
    }
  }, [module, observation]);

  return secrets;
};
