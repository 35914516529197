import styled from 'styled-components';
export const ImageContainer = styled.img`
    width:100%; 
    max-width:165px;
    margin:15px auto;
    cursor:pointer;
    @media (min-width:426px) and (max-width:767px)  {
        max-width:195px;
    }
    @media (min-width:320px) and (max-width:425px)  {
        margin:auto;
        max-width:100%;
    }
`;

export const ImgWarrper = styled.div`
    margin:auto;
    @media (min-width:320px) and (max-width:425px)  {
        ${({ count }) => count ? `
            margin: 80px auto;
        `:
        `
        img{
            max-width: 85%;
        }
        `}
    }
`;
