import styled  from 'styled-components';

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
  }
  
  &:first-of-type {
    margin-right: 5px;
    transform-origin: left bottom;
    align-items: flex-start;

    &:after {
      border-left: 20px solid #DCE2E8;
      border-right: 20px solid transparent;
      border-top: 20px solid #DCE2E8;
      border-bottom: 20px solid transparent;
    }
    
    ${props => props.circular && `
      border-radius: 50% 50% 50% 0;
    `}
  }
  &:last-of-type {
    margin-left: 5px;
    transform-origin: right bottom;
    align-items: flex-end;

    &:after {
      border-left: 20px solid transparent;
      border-right: 20px solid #DCE2E8;
      border-top: 20px solid #DCE2E8;
      border-bottom: 20px solid transparent;
    }
    
    ${props => props.circular && `
      border-radius: 50% 50% 0 50%;
    `}
  }
  
  transform: scale(${props => props.transform})
`;

export const SliderImage = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
  border-radius: ${props => props.circular ? '50%' : '0%'};
`;

export const ImageContainer = styled.div`
  display: flex;
  & > div {
    width: 100%;
  }
  border-radius: ${props => props.circular ? '50%' : '0%'};
`;

const ImageContainerBorder = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    padding-top: 100%;
    display: block;
  }
  
  ${props => props.circular && `
    background-color: #DCE2E8;
    border: 15px solid #DCE2E8;
  `}
`;

export const LeftImageContainerBorder = styled(ImageContainerBorder)`
  ${props => props.circular && `
    border-radius: 50% 50% 50% 0;
  `}
`;

export const RightImageContainerBorder = styled(ImageContainerBorder)` 
  ${props => props.circular && `
    border-radius: 50% 50% 0 50%;
  `}
`;
