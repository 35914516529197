import styled from "styled-components";

export const CenteredText = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const Image = styled.div`

  .img{
    width:70%;
  }
@media(min-width:320px)and (max-width:700px){
 .img{
    width:100%;
  }
}

`;
