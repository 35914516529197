"use strict";

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import _get from 'lodash.get';

import timerConfig from '../../config/timer';
import {
	TimerWrapper,
	Time,
	TimerAddButton,
	TimerCancelButton,
	TimerExtension,
	TimerExtensionOption,
	TimerFooter,
	Status,
	Clock
} from './TimerStyles';

const clockRadius = 11;

class Timer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.setDefaults()
		}
	}

	componentDidMount() {
		this.init()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let state = {};

		if (this.props.timerId !== prevProps.timerId) {
			state = this.setDefaults();
			this.setState(state, this.init)
		} else {
			let state = {};

			if (this.props.timerPaused !== prevProps.timerPaused) {
				state.timerPaused = this.props.timerPaused;
				this.updateTimerStatus(this.props.timerPaused);
				this.setState({ disabled: this.props.timerPaused });
			}

			// if (this.props.time !== prevProps.time) {
			// 	state.timerTime = this.props.time
			// }

			if (Object.keys(state).length) {
				this.setState(state)
			}
		}
	}

	init() {
		this.setupTimerLabel(this.state.timerTime)
		this.updateTimerPath(359.9)
		this.props.timerInitializedHandler()
		this.startTimer()
	}

	getCurrentTime() {
		return new Date().getTime()
	}

	setDefaults = (returnState = true) => {
		const currentTime = this.getCurrentTime()
		const defaultState = {
			timerPath: '',
			timerLabel: '00:00',
			timerFinished: false,
			startTimestamp: currentTime,
			elapsedTime: 0,
			showExtensionModal: false,
			timeExtension: 0,
			extensionCount: 0,
		}
		const { time, timerPaused } = this.props
		const extensionOptions = this.props.extensionOptions || timerConfig.extensionOptions


		const state = {
			...defaultState,
			timerPaused,
			extensionOptions,
			timerTime: time,
			checkboxesState: Array(extensionOptions.length).fill(false),
		}

		this.initTimerTimestamp = currentTime

		if (returnState) {
			return state
		}

		this.setState(state)
	}

	startTimer() {
		const { timerPaused, timerFinished, timerTime } = this.state
		const { defaultTimeout } = timerConfig
		if (timerPaused || timerFinished) {
			return
		}

		let timeout = timerTime / 360
		if (timeout > defaultTimeout) {
			timeout = defaultTimeout
		}

		this.setState({
			timeout,
			startTimestamp: this.getCurrentTime()
		}, () => {
			this.props.timerStartedHandler()
			this.refresh()
		})
	}

	refresh() {
		const { elapsedTime, startTimestamp, timerTime, timeout, timerPaused, timerFinished, extensionCount } = this.state
		const { allowExtension, timerExtensionModalOpenedHandler, extentionPopup } = this.props
		const { cancelTimeExtensionHandler } = this;
		if (timerPaused || timerFinished) {
			return
		}

		const timer = this
		const currentElapsedTime = elapsedTime + new Date().getTime() - startTimestamp
		let currentAngle;

		if (timerTime < 0) {
			currentAngle = 0;
		}
		else {
			currentAngle = 360 - (currentElapsedTime / timerTime * 360)
		}

		if (currentAngle >= 360) {
			currentAngle = 359.9;
		}

		const remainingTime = timerTime - currentElapsedTime

		this.setupTimerLabel(remainingTime)
		this.updateTimerPath(currentAngle)

		if (remainingTime >= 0) {
			this.refreshTimeOut = setTimeout(() => {
				timer.refresh()
			}, timeout)
		} else if (remainingTime <= 0) {
			this.setState(currentState => {
				const updatedState = {
					timerFinished: true
				}
				if (allowExtension) {
					if (extentionPopup && (extensionCount >= extentionPopup)) updatedState.showExtensionModal = false
					else updatedState.showExtensionModal = true
				}

				return updatedState
			}, () => {
				if (this.state.showExtensionModal) {
					this.extensionModalStartTimestamp = new Date().getTime();
					this.props.timerExtensionModalOpenedHandler()
				} else {
					extentionPopup && cancelTimeExtensionHandler()
					this.props.timerFinishedHandler()
				}
			})
		}
	}

	componentWillUnmount = () => {
		const { refreshTimeOut } = this;
		if (refreshTimeOut) {
			clearTimeout(refreshTimeOut)
		}
	}

	updateTimerPath(angle) {
		if (angle > 0) {
			let rad = (angle * Math.PI / 180);
			let x = Math.round(Math.sin(rad) * -clockRadius * 1000) / 1000;
			let y = Math.round(Math.cos(rad) * -clockRadius * 1000) / 1000;
			let mid = (angle > 180) ? 1 : 0;
			let anim = 'M 0 0 v -' + clockRadius + ' A ' + clockRadius + ' ' + clockRadius + ' 1 '
				+ mid + ' 0 '
				+ x + ' '
				+ y + ' z';

			this.setState({ timerPath: anim })
		}
	}

	setupTimerLabel(timeInMilliSec) {
		const time = (timeInMilliSec < 0) ? 0 : timeInMilliSec;
		let min = Math.floor(time / 60000);
		let sec = Math.ceil((time % 60000) / 1000);
		if (sec == 60) {
			min++;
			sec = 0;
		}
		if (sec < 10) {
			sec = `0${sec}`;
		}
		// sec = `${sec}`.padStart(2, '0');
		this.setState({ timerLabel: `${min}:${sec}` })
	}

	handleTimerButton = () => {
		const { timerFinished } = this.state;

		if (timerFinished || !this.props.enableControlButton || this.state.disabled) {
			return;
		}

		this.updateTimerStatus(!this.state.timerPaused, true);
	}

	updateTimerStatus(timerPaused) {
		this.setState(({ elapsedTime, startTimestamp }) => {
			const newState = {
				timerPaused
			};

			if (timerPaused) {
				newState.elapsedTime = elapsedTime + new Date().getTime() - startTimestamp;
			}

			return newState
		}, () => {
			this.props.timerPausedHandler(timerPaused);
			if (!timerPaused) this.startTimer()
		})
	}

	handleTimeExtension = (i, sec) => {
		this.setState(({ checkboxesState }) => {
			let updatedState = {
				checkboxesState: [...checkboxesState].fill(false),
				timeExtension: (sec * 1000)
			};

			if (!checkboxesState[i]) {
				updatedState.checkboxesState[i] = !checkboxesState[i];
			} else {
				updatedState.checkboxesState[i] = checkboxesState[i];
			}
			return updatedState;
		});
	}

	cancelTimeExtensionHandler = () => {
		this.setState({
			showExtensionModal: false
		}, () => {
			this.props.timerExtensionCancelledHandler()
		})
	}

	helpModalClosedHandler = () => {
		const { timerFinished } = this.state

		if (timerFinished) {
			this.props.timerFinishedHandler()
		}
	}

	extendTime = () => {
		this.setState({ extensionCount: this.state.extensionCount + 1 });
		const currentTimeStamp = this.getCurrentTime()
		const extensionModalTimestamp = currentTimeStamp - this.extensionModalStartTimestamp
		const { timeExtension } = this.state
		if (!this.props.allowExtension) {
			return;
		}

		this.setState(currentState => {
			const { checkboxesState } = currentState

			return {
				timerTime: timeExtension,
				timerLabel: '00:00',
				timerPaused: false,
				timerFinished: false,
				elapsedTime: 0,
				showExtensionModal: false,
				timeExtension: 0,
				checkboxesState: [...checkboxesState].fill(false)
			}
		}, () => {
			this.startTimer()
			this.props.timerExtendedHandler(timeExtension, extensionModalTimestamp)
		})
	}

	renderExtModal = () => {
		const { helpModalClosedHandler, cancelTimeExtensionHandler, handleTimeExtension, extendTime } = this
		const { extentionButtonDesign } = this.props;
		const { timeExtension, extensionOptions, checkboxesState, showExtensionModal } = this.state

		const extensionOptionsList = extensionOptions.map((option, i) => {
			const duration = _get(this.props, 'translation.timer_modal.duration', 'Seconds');
			return (
				<TimerExtensionOption key={option}>
					<input
						type="radio"
						checked={checkboxesState[i]}
						value={extensionOptions[i]}
						onChange={() => handleTimeExtension(i, option)} />
					{` ${option} ${duration}`}
				</TimerExtensionOption>
			)
		});
		return <TimerExtension isOpen={showExtensionModal} onClosed={helpModalClosedHandler}>
			<ModalBody>
				<h3 /*className={this.props.timerId.match("rt_level") ? "" : "header"}*/>{_get(this.props, 'translation.timer_modal.header', 'Would you like an extension?')}</h3>
				<p>{_get(this.props, 'translation.timer_modal.sub_header', 'Your time has expired, but you may select to extend your time if you like.')}</p>
				<p>{_get(this.props, 'translation.timer_modal.hint', 'Choose time to add:')}</p>
				{extensionOptionsList}
			</ModalBody>
			<TimerFooter buttondesign={extentionButtonDesign}>
				<TimerCancelButton buttondesign={extentionButtonDesign} onClick={cancelTimeExtensionHandler}>{_get(this.props, 'translation.timer_modal.cancel_extend', 'NO THANKS')}</TimerCancelButton>{' '}
				<TimerAddButton buttondesign={extentionButtonDesign} hideAfter={this.props.timerId.match("rt_level") ? "" : "header"} disabled={!timeExtension} onClick={extendTime}>{_get(this.props, 'translation.timer_modal.add_extend', 'ADD TIME') + ' '}</TimerAddButton>
			</TimerFooter>
		</TimerExtension>
	};

	render() {
		const { timerPath, timerFinished, timerLabel } = this.state;

		const clock = timerPath ? (<svg viewBox="0 0 22 22">
			<path transform="translate(11, 11)" d={timerPath} />
		</svg>) : null;

		return <Fragment>
			<TimerWrapper
				onClick={this.handleTimerButton}
				allow_control={!this.state.disabled && this.props.enableControlButton}
				visible={this.props.visible}
				paused={this.state.timerPaused}
			>
				<Clock>{clock}</Clock>
				<Time>{timerLabel}</Time>
				{!this.state.disabled && !timerFinished && this.props.enableControlButton && <Status>{this.state.timerPaused ? 'P' : 'p'}</Status>}
			</TimerWrapper>
			{this.renderExtModal()}
		</Fragment>
	}
}

Timer.defaultProps = {
	timerId: '',
	time: 3000,
	visible: false,
	timerPaused: false,
	enableControlButton: true,
	allowExtension: true,
	timerInitializedHandler: () => { },
	timerStartedHandler: () => { },
	timerFinishedHandler: () => { },
	timerPausedHandler: () => { },
	timerExtendedHandler: (extension, modalTimestamp) => { },
	timerExtensionCancelledHandler: () => { },
	timerExtensionModalOpenedHandler: () => { }
};

Timer.propTypes = {
	timerId: PropTypes.string,
	allowExtension: PropTypes.bool,
	time: PropTypes.number,
	timerPaused: PropTypes.bool,
	enableControlButton: PropTypes.bool,
	timerInitializedHandler: PropTypes.func,
	timerStartedHandler: PropTypes.func,
	timerFinishedHandler: PropTypes.func,
	timerPausedHandler: PropTypes.func,
	timerExtendedHandler: PropTypes.func,
	timerExtensionCancelledHandler: PropTypes.func,
	timerExtensionModalOpenedHandler: PropTypes.func,
};

export default Timer