'use strict';

import React, { Fragment, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GlobalBaseStyle, CenterContent, Warning } from '../../assets/styles/BaseStyle';
import { CenteredText, HintText } from './DigitSpanStyles';
import {getRandomNumber} from '../../helpers/memory_game';

import Input from '../TextInput/Input';
import Instructions from '../Instructions/Instructions';

import {extract_text} from '../../helpers';

import {LocaleContext} from '../../context/LocalContext';
import _get from 'lodash.get';
import {loadImage} from '../../helpers/image_loader';
import {InstructionImage} from '../Instructions/InstructionsStyle';
import {Nav, NavButtons, ArrowRight} from '../../components/Player/PlayerStyle';

const defaults = {
  generate: (props = {}) => {
    const defaultProps = {
      image: 'digit_span_male_1',
      ...props
    };

    return {
      ...defaultProps,
      observation: props.alias || props.observation
    };
  },
  remind: (props = {}) => {
    const defaultProps = {
      image: 'digit_span_male_2',
      ...props
    };

    return {
      ...defaultProps,
      observation: props.alias || props.observation
    };
  },
};

const MemoryGame = ({ alias, observation, generate, remind, demo, question, data_type, onChangeHandler, onReady, handleNavigation }) => {
  const translation = useContext(LocaleContext);

  const [generateState, setGenerateState] = useState(true);
  const [random_number, setRandomNumber] = useState('');
  const [demo_hint, setDemoHint] = useState(null);
  const [remindState, setRemindState] = useState(false);
  const [answerState, setAnswerState] = useState(false);
  const [number_length, setNumberLength] = useState(5);
  const [last_answer, setLastAnswer] = useState(false);

  useEffect(() => {
    let generate_length = last_answer ? number_length + 2 : number_length - 1;
    if (generate_length < 1) generate_length = 1;
    setNumberLength(generate_length);

    const secret = demo ? _get(demo, 'answer', 35) : getRandomNumber(generate_length);

    setAnswerState(false);
    setRemindState(false);
    setGenerateState(true);
    setRandomNumber(secret);

    const generateScreenTimeout = _get(generate, 'delay', 5000);
    const remindScreenTimeout = _get(remind, 'delay', 5000);
    const demoHintDelay = _get(demo, 'hint_delay', 15000);

    const genKeyTime = setTimeout(() => {
      setGenerateState(false);
      setRemindState(true);
    }, generateScreenTimeout);

    let demoTime = null;

    const remindKeyTime = setTimeout(() => {
      if (demo) {
        demoTime = setTimeout(() => {
          setDemoHint(_get(demo, 'answer', 35));
        }, demoHintDelay);
      }

      setRemindState(false);
      setAnswerState(true);

      if (typeof onReady === 'function') onReady(observation, true);
    }, generateScreenTimeout + remindScreenTimeout);

    return () => {
      clearTimeout(genKeyTime);
      clearTimeout(remindKeyTime);
      clearTimeout(demoTime);
      handleInputChange(null, secret);
    }
  }, [observation]);

  const handleInputChange = (value = null, secret = random_number) => {
    const ev = Number(value);

    const correct = ev === secret;

    setLastAnswer(correct);

    const observation = {
      answer: value,
      generated: secret
    };

    const if_demo = correct ? observation : null;

    onChangeHandler(demo ? if_demo : value && value.length >= 1 ? observation : null);
  };

  const demo_text = _get(demo, 'hint_text', null);

  const renderInput = () => {
    return <>
      <CenteredText>{extract_text(question) || _get(translation, `${alias || observation}.question`)}</CenteredText>
      <Input data_type={data_type} onChangeHandler={handleInputChange} autoFocus={true} allowPaste={false}/>
      <Warning visible={demo_hint} size={'large'}>{demo_text ? demo_text + demo_hint : _get(translation, `${observation}.error_message`)}</Warning>
      <CenteredText>{
        <InstructionImage width={100}>
          <img src={loadImage('digit_span_male_3')} alt="keyboard"/>
        </InstructionImage>
      }</CenteredText>
      <Nav>
        <div />
        <NavButtons visible={demo} enabled={true} onClick={handleDemoNavigate}><ArrowRight /></NavButtons>
      </Nav>
    </>
  };

  const handleDemoNavigate = () => {
    if (!last_answer){
      setDemoHint(_get(demo, 'answer', 35));
      return;
    }

    if (typeof handleNavigation === 'function') handleNavigation(1)
  };

  return (
    <Fragment>
      <GlobalBaseStyle />
      <CenterContent center={true}>
        <CenteredText>
          { generateState && <Instructions {...defaults.generate(generate)} hint={random_number} />}
          { remindState && <Instructions {...defaults.remind(remind)} /> }
        </CenteredText>
        { answerState && renderInput()}
      </CenterContent>
    </Fragment>
  )
};

MemoryGame.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sub_title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default MemoryGame
