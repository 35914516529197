"use strict";

import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import { GlobalBaseStyle, CenterContent } from "../../assets/styles/BaseStyle";
import {
  LeftImage,
  RightImage,
  Mobile,
  NotMobile,
} from "./GameInstructionsStyle";
import { Hint, Question } from "../../assets/styles/BaseStyle";
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";

const GameInstructionsScreen = ({
  observation,
  image,
  imageMobile,
  instruction,
  hint,
  sub_title,
  sub_title_1,
}) => {
  const translation = useContext(LocaleContext);
  const imageType = typeof image;

  const _hint =
    extract_text(hint) ||
    _get(
      translation,
      `${observation}.hint`,
      _get(
        translation,
        `${observation}.instruction2`,
        _get(translation, `${observation}.subtitle`)
      )
    );

  return (
    <Fragment>
      <GlobalBaseStyle />
      <CenterContent center={true}>
        <Question alignment={_get(instruction, "alignment", "center")}>
          {extract_text(instruction) ||
            _get(
              translation,
              `${observation}.title`,
              _get(
                translation,
                `${observation}.instruction`,
                _get(translation, `${observation}.instruction1`)
              )
            )}
        </Question>

        <NotMobile>
          <div>
            {extract_text(sub_title) ||
              _get(translation, `${observation}.subtitle`)}
          </div>
          <img src={loadImage(image)} className="center" />
        </NotMobile>
        <Mobile>
          <div>
            {extract_text(sub_title_1) ||
              _get(translation, `${observation}.subtitle_1`)}
          </div>
          <img src={loadImage(imageMobile)} className="center" />
        </Mobile>
      </CenterContent>
    </Fragment>
  );
};

GameInstructionsScreen.defaultProps = {
  image: "",
};

GameInstructionsScreen.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sub_title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
};

export default GameInstructionsScreen;
