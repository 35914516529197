import styled from 'styled-components'
import { RaisedButton } from '../../assets/styles/BaseStyle';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';

export const ModalContainer = styled(Modal)`
  [class~="modal-content"] {
		border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: .3rem;
	}
`;

export const Header = styled(ModalHeader)`
  border: 0;
  padding: 10px 15px;
`;

export const Instructions = styled.div`
  border: 0;
  padding: 1rem 1rem;
`;

export const Footer = styled(ModalFooter)`

${({ hidden }) => hidden && "display:none;"}

  border: 0;
  justify-content: space-between;
  margin-top: 30px;
  
  @media (max-width: 576px) {
   display: block
  }
  
  @media (max-width: 480px) {
    margin-top: 0;
  }
`;

export const Buttons = styled(ModalFooter)`
${({ hidden }) => hidden && "display:none;"}
  border: 0;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 480px) {
    margin-top: 0;
    padding: 13px 0px !important;
    padding-top: 0px !important;
  }
`;

export const Body = styled(ModalBody)`
  position: relative;
  padding:0px;
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    ${({ clickable }) => clickable === "true" ? "z-index: -1;" : "z-index: 2;"}
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }
`;

const Button = styled(RaisedButton)`
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  margin: 10px 0;
  display: block;
  border: 0;
  padding: 0.375rem 0.75rem;
  outline: 0;
  min-width: 120px;
  text-transform: uppercase;

  &:disabled {
    background: #DDE2E8;
  }
  
  @media (max-width: 576px) {
    margin: 10px auto 0px !important; 
  }
`;

export const NextButton = styled(Button)`
  background: ${({ is_last, theme }) =>  theme.button.help.navigation.background };
`;

export const PreviousButton = styled(Button)`
  visibility: ${({ is_first }) => is_first ? 'hidden' : 'visible'};
  background: ${({ theme }) => theme.button.help.navigation.background};
`;

export const Trigger = styled(RaisedButton)`
display: ${({ visible }) => visible ? 'flex' : 'none'};
align-items: center;
justify-content: center;
padding: 3px 14px;
border-radius: 30px;
background: #BD1E6F;
outline: none;
font-size: 19px;
color: white;
margin: 0 10px;
border: 0;
&:hover {
background: #eceff2;
}
`;
export const ImageContainer = styled.div`
  display:flex;
  text-align:center;
  align-items:center;
  flex-direction:column;
  ${(props) =>
    !props.isFirebuilding &&
    `img {
    width: 100%; 
    max-width: 300px; 
    max-height: 320px; 
    height: 100%; 
    margin: 0px auto;
    padding: 10px 0px;
  }`
  }
  .image-content {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;  
    img{
        width: 300px;
        min-width: 300px;
        margin:0px auto; 
        @media (max-width: 300px) {
          width: 260px;
          min-width: 260px;
        }
    }
    .img_3{
      padding: 0px 12px 0px 9px;
    }
    
    .fire_txt{
      position: absolute;
      left: 25px;
      right: 0;
      font-size: 9px;
      top: 223px;
      @media (max-width: 300px) {
        top: 193px;
      }
    }
    .subImdTxt {
      position: absolute;
      font-size: 9px;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 300px;
      text-align:left;
  
      @media (max-width: 300px) {
        width:260px
      }

      p{
        display:flex;
        span{
          white-space: nowrap;
        } 
        .apOne {
          margin-left: 9%;
          min-width: 18%;
          text-align: left;
        }
        .apTwo {
            margin-left: 7% ;
            text-align: center;
            min-width: 25%;
        }
        .apThree {
            margin-right: 4% ;
            margin-left: 11% ;
            text-align: center;
            min-width: 10%;
        }
        .apFour {
            margin-left: 0;
        }
    }
    
    p.en{
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 1% !important;
      }
      .apThree {
          margin-right: 3% !important;
          margin-left: 5% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 1% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 5% !important;
        }
      }
    }
  
    p.es,
    p.es-es{
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 0% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 5% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 0% !important;
        }
        .apThree {
            margin-right: 1% !important;
            margin-left: 4% !important;
        }
      }
    }
    p.th{
      .apOne {
        margin-left: 7%;
      }
      .apTwo {
        margin-left: 0% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 7% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 7%;
        }
        .apTwo {
          margin-left: 0% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 7% !important;
        }
      }
    }
  
    p.fr{
      .apOne {
        margin-left: 7%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 5% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 5%;
        }
        .apTwo {
          margin-left: 2% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 6% !important;
        }
      }
    }
  
    p.el{
      .apOne {
        margin-left: 5%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 5% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 5%;
        }
        .apTwo {
          margin-left: 2% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 6% !important;
        }
      }
    }
    p.ny{
      .apOne {
        margin-left: 2%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 3% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 2%;
        }
        .apTwo {
          margin-left: 0% !important;
        }
        .apThree {
            margin-right: 1% !important;
            margin-left: 0% !important;
        }
      }
    }
    p.lg{
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 4% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 3%;
        }
        .apTwo {
          margin-left: 2% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 4% !important;
        }
      }
    }
    p.rw{
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 7% !important;
      }
      
      @media (max-width: 300px) {
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 2% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 6% !important;
        }
      }
    } 
  }
    // scaled images

    .scaled{
      padding: 0px 14px 0px 11px;
      p.rw{
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 11% !important;
        }
        .apThree {
            margin-right: 3% !important;
            margin-left: 16% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 2%;
          }
          .apTwo {
            margin-left: 11% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 16% !important;
          }
        }
      } 
      p.lg{
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 11% !important;
        }
        .apThree {
            margin-right: 3% !important;
            margin-left: 12% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 3%;
          }
          .apTwo {
            margin-left: 11% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 12% !important;
          }
        }
      }
      p.ny{
        .apOne {
          margin-left: 2%;
        }
        .apTwo {
          margin-left: 9% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 11% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 2%;
          }
          .apTwo {
            margin-left: 9% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 11% !important;
          }
        }
      }
      p.fr{
        .apOne {
          margin-left: 5%;
        }
        .apTwo {
          margin-left: 12% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 17% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 3%;
          }
          .apTwo {
            margin-left: 12% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 17% !important;
          }
        }
      }
    p.el{
        .apOne {
          margin-left: 5%;
        }
        .apTwo {
          margin-left: 12% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 17% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 3%;
          }
          .apTwo {
            margin-left: 12% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 17% !important;
          }
        }
      }
      p.th{
        .apOne {
          margin-left: 5%;
        }
        .apTwo {
          margin-left: 11% !important;
        }
        .apThree {
            margin-right: 3% !important;
            margin-left: 17% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 5%;
          }
          .apTwo {
            margin-left: 11% !important;
          }
          .apThree {
              margin-right: 4% !important;
              margin-left: 17% !important;
          }
        }
      }
      p.es,
      p.es-es{
        margin: 0px -14px 0px -11px;
        padding-left: 14px; 
        .apOne {
          margin-left: 4%;
        }
        .apTwo {
          margin-left: 9% !important;
        }
        .apThree {
            margin-right: 5% !important;
            margin-left: 12% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 5%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 4% !important;
              margin-left: 12% !important;
          }
        }
      }
      p.en{
        .apOne {
          margin-left: 2%;
        }
        .apTwo {
          margin-left: 11% !important;
        }
        .apThree {
            margin-right: 4% !important;
            margin-left: 15% !important;
        }
        
        @media (max-width: 300px) {
          .apOne {
            margin-left: 2%;
          }
          .apTwo {
            margin-left: 11% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 15% !important;
          }
        }
      }
    } 
  }
`;

export const ObjectImg= styled.img`
 width: 100%; 
 max-width: 300px; 
 max-height: 320px; 
 height: 100%; 
 margin: 0px auto;
 padding: 10px 0px;
`