import styled from 'styled-components';

import hand from '../../assets/images/hand.svg'

export const CenteredText = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size:20px;
`;
export const BoxContainer = styled.div`
    display: flex;
	  align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-top:40px;
    div{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 170px;
        height: 185px;
        border-radius: 10px;
        border: 2px solid #eee;
        padding:12px 10px;
        box-shadow: 0 0 6px rgb(0 0 0 / 30%), 0 6px 12px rgb(0 0 0 / 40%);
        margin-left:6px;
        margin-right:6px;
        span{
            margin-top: auto;
        }
    }
	@media (max-width: 768px) {
    div{
        padding:12px 5px;
        height: 130px;
    }
	}
`;



export const Option = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-top: .625em;
  margin-bottom: 0;
  display: flex;
  flex-direction: ${({ type }) => type === 'image' ? 'column' : 'row'};
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  border-radius: 4px;
  
  :after {
    ${({ pointer, theme }) => pointer && `
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      z-index: 1;
    `}
    
    top: ${({ type }) => type === 'image' ? '20%' : '60%'};
    left: ${({ type }) => type === 'image' ? '25px' : '25px'};
    ${({ hide_hands }) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}
    path: { fill: orange; }
  }
`;