"use strict";

import React, { useContext, useEffect, useState } from "react";
import Slider from "rc-slider";
import PropTypes from "prop-types";
import numeral from "numeral";
import _get from "lodash.get";

import {
  SliderBaseContainer,
  SliderBaseLabel,
  BaseOption,
  SliderBase,
  SliderBaseBackground,
} from "../../components/BaseSlider/BaseSliderStyle";
import {
  GlobalBaseStyle,
  CenterContent,
  Question,
  Hint,
  Instruction,
} from "../../assets/styles/BaseStyle";

import BaseSlider from "../../components/BaseSlider/BaseSlider";
import SliderHandle from "../../components/BaseSlider/SliderHandle";
import { RcSliderStyle } from "../../assets/styles/RCSliderStyle";
import sliderBackground from "../../assets/images/slider-bg.svg";
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";

import { loadImage } from "../../helpers/image_loader";
import { SliderImage } from "./GameSimpleSliderStyles";

const GameSimpleSlider = ({
  options,
  title,
  sub_title,
  value,
  defaultValue,
  observation,
  min,
  max,
  marks,
  step,
  handPointer,
  prefix,
  suffix,
  separator,
  onChange,
  onAfterChange,
  center,
  image,
  instruction,
}) => {
  const translation = useContext(LocaleContext);

  const applyFormatting = (value) => {
    const evaluatedSuffix =
      typeof suffix === "function" ? suffix(value) : suffix;
    const formattedValue = separator
      ? numeral(value).format(`0${separator}0`)
      : value;

    let formatting = "";

    if (prefix) formatting += `${prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;

    return formatting;
  };

  const [sliderValue, setSliderValue] = useState(value || defaultValue);

  useEffect(() => {
    setSliderValue(value || defaultValue);
  }, [observation]);

  const handleSlide = (value) => {
    const offset = 100 / options.length;
    const idx = Math.ceil(value / offset - 1);
    const opt_label = _get(options[idx], "label") || applyFormatting(value);

    setSliderValue(value);

    if (typeof onChange === "function") onChange(value, opt_label);
  };

  const props = {
    onAfterChange,
    onChange: handleSlide,
    max,
    min,
    step,
    marks,
    value: sliderValue,
    handle: SliderHandle,
    key: "slider",
  };

  const slider_options = options.map((opt, i) => {
    const opt_label =
      _get(opt, "label") ||
      _get(translation, `${observation}.option${i + 1}`) ||
      applyFormatting(i);
    return (
      <BaseOption align={"left"} key={i}>
        {opt_label}
      </BaseOption>
    );
  });

  return (
    <>
      <GlobalBaseStyle />
      <RcSliderStyle />
      <CenterContent center={center}>
        {extract_text(sub_title) ||
          _get(translation, `${observation}.sub_title1`)}{" "}
        {}{" "}
        <SliderImage>
          <img src={loadImage(image)} />
        </SliderImage>
        {extract_text(sub_title) ||
          _get(translation, `${observation}.question`)}{" "}
        {}{" "}
        <SliderBaseContainer pointer={handPointer}>
          <SliderBaseLabel>{slider_options}</SliderBaseLabel>
          <SliderBase>
            <SliderBaseBackground src={sliderBackground} />
            <Slider {...props} />
          </SliderBase>
        </SliderBaseContainer>
      </CenterContent>
    </>
  );
};

GameSimpleSlider.defaultProps = {
  title: "",
  sub_title: "",
};

export default BaseSlider(GameSimpleSlider);
