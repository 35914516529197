'use strict'

import React, {Component, Fragment, cloneElement} from 'react'
import _get from 'lodash.get'
import moment from 'moment'

import config from '../config/date-time'

export const dayMonthOptions = (params) => {
	let dayOptions = []
	let monthOptions = []

	for(let x = 1; x <= 31; x++) {
		dayOptions.push({
			value: x,
			label: x
		})

		if (x <= 12) {
			monthOptions.push({
				value: x,
				label: x
			})
		}
	}

	return {
		dayOptions,
		monthOptions
	}
}

export const validate = (params) => {
	const {defaultFormat} = config
	const format = _get(params, 'format', defaultFormat)
	const {value, min, max} = params
	const minimum = moment(min, format)
	const maximum = moment(max, format)
	const dateObject = moment(value, format)

	if (! dateObject.isValid()) {
		return 'INVALID_DATE'
	} else if (dateObject.isBefore(minimum.format(format))) {
		return 'BELOW_MIN'
	} else if (dateObject.isAfter(maximum.format(format))) {
		return 'OVER_MAX'
	}

	return true
}
