import styled from 'styled-components';
import hand from '../../assets/images/hand.svg'
export const Image = styled.div`

	img {
		width: 100%;
		max-width: 350px;
		margin: 0px auto 20px;
    display: block;
	}
`;
export const Group = styled.div`
  text-align: left;
    margin: 50px auto;
${({ rowGroupStyle }) => rowGroupStyle && `
  width: 67%;
  margin: 29px auto 25px auto;
  @media (min-width:481px) and (max-width:767px){
    width: 68%;
  }
  @media (min-width:320px) and (max-width:480px){
    width: 100%;
  `}
`;

export const Option = styled.div`
  display: flex;
  align-items: baseline;
	position: relative;
	cursor: pointer;
  background: #F0F3F7;
  padding: 1px;
  border-radius: 4px;
  margin: 15px 0;

  input {
    margin: 10px;
  }
	label {
		margin: 0;
	}
  &:hover {
    background: rgba(238, 242, 246, 0.5);
  }
  
  ${({ pointer }) => pointer && `
    :after {
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 300px;
      background: url(${hand}) center center no-repeat;
      z-index: 1;
    }`
  }
`;