import styled from 'styled-components'

export const Button = styled.div`
    margin:30px auto;
    background: ${({ theme }) => theme.colors.primary};
    font-size:18px;
    text-align: center;
    width: 40%;
    border-radius: 50px;
    padding: 10px 0px;
    color:#fff;
    border: none;
    outline: none;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
    &:active,
        &.active {
            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
            border: 0;
        }
        &:focus {
            outline: none;
        }
    @media (min-width:320px) and  (max-width:767px){
        width:100%;
    }
    `;