import styled from 'styled-components';
import hand from '../../assets/images/hand.svg'

export const SelectButton = styled.button`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px;
  position: relative;
  border: 1px solid #ced4da;
  text-align: left;
  ${({ v2 }) => v2 && `
    border-radius: 30px;
    background: #F5F5F5;
    padding: 10px 20px;
  `};
  &:after {
  position: absolute;
    right: 10px;
    top: 41%;
  } 
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  display: block;
  padding-top: 0;
  padding-right: 3px;
  background-color: #FFF;
  float: left;
  
  :after {
    ${({ pointer }) => pointer && `

      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 60%;
      left: 20px;
      z-index: 1;
    `}
    
    ${({ hide_hands }) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}
  }
`;

export const SelectMenu = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  height: 169px;
  top: ${({ v2 }) => v2 ? `54px` : `-84px`};
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  ${({ help }) => help || `
  position: absolute;
  `}
  /*  */
  z-index: 1;
  min-width: 100%;
  overflow-y: auto;
  padding: 8px 0;
  background-color: #FFF;
  border: 1px solid #e0e0e0;
  /* box-shadow: 1px 0px 0px 0px rgba(0,0,0,.12), inset 1px 0px 0px 0px rgba(0,0,0,.12); */
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c8cc;
    border-radius: 15px;
    margin-right: 15px;
    width: 10px;
  }
`;

export const SelectOptions = styled.div`
  padding: 0 22px;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  white-space: nowrap;
  ${({ selected }) => selected && 'background-color: #EEEEEE'};
  
  &:hover {
    background-color: #E0E0E0;
  }

  :after {
    ${({ pointer }) => pointer && `

      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 60%;
      left: 20px;
      z-index: 1;
    `}
    
    ${({ hide_hands }) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}

    
`;