import styled, { createGlobalStyle } from 'styled-components';
import hand from '../../assets/images/hand.svg'
export const Image = styled.div`

	img {
		width: 100%;
		max-width: 350px;
		margin: 0px auto 20px;
    display: block;
	}
`;
export const Group = styled.div`
@media (max-width:768px){
  text-align: center;
}
  text-align: left;
  margin-top: 20px;

${({ rowGroupStyle }) => rowGroupStyle &&
    `}

  width: 67%;
  margin: 29px auto 25px auto;
  @media (min-width:481px) and (max-width:767px){
    width: 68%;
  }
  @media (min-width:320px) and (max-width:480px){
    width: 100%;
  `}
`;

export const Option = styled.div`
  display: flex;
  align-items: baseline;
	position: relative;
	cursor: pointer;
  background: #F0F3F7;
  padding: 1px;
  border-radius: 4px;
  margin: 15px 0;

  input {
    margin: 10px;
  }
	label {
		margin: 0;
	}
  &:hover {
    background: rgba(238, 242, 246, 0.5);
  }
  
  ${({ pointer }) => pointer && `
    :after {
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 300px;
      background: url(${hand}) center center no-repeat;
      z-index: 1;
    }`
  }
`;

export const RateStyle = createGlobalStyle`
.ratingRadio {
  background-color: white !important;
  flex-direction: column-reverse !important;
  display: inline !important;
  position: relative !important;
  padding: 10px 18px;
}
.ratingRadio label
{
  position: absolute !important;
    left: 0;
    top: -24px;
    font-size: 13px;
    margin: 0 auto;
}
.ratingRadio img{
    position: absolute !important;
    left: 23px;
    top: -68px;
    margin: 0 auto;
    width: 55%;
}
.qusAlign {
    margin-top: 30px;
    margin-bottom: 85px;
}
@media (max-width: 768px) {
  .ratingRadio {
    padding: 4px 0px;
  }
  .ratingRadio img{
    width: 65%;
    left: 10px;
    top: -56px;
  }
  .qusAlign {
    text-align: center;
  }

}
@media (max-width: 400px) {
  .ratingRadio {
    font-size: 13px;
  }
}

@media (min-width:481px) and  (max-width:528px){
  .ratingRadio {
    font-size: 14px;
  }	
}

}`;

export const NavButtons = styled.button`
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1.8em;
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 25px;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
`;

export const Nav = styled.div`
    display: flex;
    padding-top: 15px;
	  padding-bottom: 15px;
    justify-content: flex-end;
`;

export const ArrowRight = styled.span`
  :before {
    font-family: efl_iconfont;
    content: '\\52'
  }
`;

export const TitleContent = styled.div`
    text-align : left;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 16px;
    }
`;