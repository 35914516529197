import styled  from 'styled-components';

export const SliderImage = styled.div`
  margin: 0 auto;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
  border-radius: ${props => props.circular ? '50%' : '0%'};

    img {
        margin: 0 auto;
        width: 100%;
        max-width: 30%;
        margin-left: 40%;
    }
`;