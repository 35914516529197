import styled, { createGlobalStyle } from 'styled-components';

export const Button = styled.button`

margin:30px 0;
background: ${({ theme }) => theme.colors.primary};
font-size:18px;
text-align: center;
width: 30%;
border-radius: 50px;
padding: 10px 0px;
color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
@media (min-width:320px) and  (max-width:767px){
	width:100%;
	margin:10px 15px
}
`;

export const ListStyle = createGlobalStyle`

	.custom-list {
		list-style: none;
		padding-left: 0;
		margin-top: 30px;
	}
	.custom-list li {
		position: relative;
		padding-left: 10%;
		margin-bottom: 40px;
	}
	.custom-list li:before {
		content: '';
		width: 45px;
		height: 45px;
		position: absolute;
		background-size: cover;
		background-position: center;
		left: 2%;
		top: 50%;
		transform: translateY(-50%);
	}
	.custom-list .listItemOne:before {
		background-image: url(listItem1.svg);
	}
	.custom-list .listItemTwo:before {
		background-image: url(listItem2.svg);
	}
	.custom-list .listItemThree:before {
		background-image: url(listItem3.svg);
	}
	.custom-list .listItemFour:before {
		background-image: url(listItem4.svg);
	}
	@media (min-width:768px) and  (max-width:1500px) {
		.custom-list li {
			padding-left: 13%;
		}	
	}
	@media (max-width: 768px) {
		.custom-list li {
			padding-left: 17%;
		}	
	}
	@media (min-width:320px) and  (max-width:500px){
		.custom-list li {
			padding-left: 20%;
		}	
	}
`;