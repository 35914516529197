"use strict";

import React, { Fragment, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import KeyboardEventHandler from "react-keyboard-event-handler";

import { GlobalBaseStyle, CenterContent } from "../../assets/styles/BaseStyle";
import {
  GameInstructionsImage,
  RightImage,
  LeftImage,
  Button,
  Mobile,
  NotMobile,
  Flip,
  Flip2, Image
} from "./GameResultStyle";
import { Hint, Question } from "../../assets/styles/BaseStyle";
import { extract_text } from "../../helpers";
import { LocaleContext } from "../../context/LocalContext";
import { loadImage } from "../../helpers/image_loader";
import _get from "lodash.get";

const GameResultScreen = ({
  observation,
  instruction,
  hint,
  title,
  title2,
  sub_title1,
  sub_title2,
  button_titile1,
  button_titile2,
  keybord,
  blankImage,
  image,
  images,
  levelCompleteImage,
  randomNumbers,
  score,
  result_from,
  previousValues
}) => {


  const translation = useContext(LocaleContext);

  return (
    <div>
      <Fragment>
        <GlobalBaseStyle />
        <CenterContent center={true}>
          <div className={`lefl-module-sub-title moduleSubTitle`}>
            {" "}
            {extract_text(sub_title1) ||
              _get(translation, `${observation}.sub_title1`)}{" "}
            {_get(previousValues, `${result_from}.value`)}{" "}
            {extract_text(sub_title2) ||
              _get(translation, `${observation}.sub_title2`)}{" "}
          </div>{" "}
          <Image>
            <img className="img" src={loadImage(levelCompleteImage)} />
          </Image>
        </CenterContent>
      </Fragment>
    </div>
  );
};

GameResultScreen.defaultProps = {
  image: "",
};

GameResultScreen.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  image: PropTypes.string,
};

export default GameResultScreen;
