import React, { useState, createRef, useEffect, useContext } from 'react';
import Slider from 'rc-slider';
import numeral from 'numeral';
import _get from 'lodash.get';

import { SliderBaseContainer, SliderBaseLabel, BaseOption, SliderBase } from '../../components/BaseSlider/BaseSliderStyle'
import {GlobalBaseStyle, CenterContent, Question, Hint, Instruction} from '../../assets/styles/BaseStyle';
import {Tooltip, ToolTipValue, TooltipTick, TooltipContainer, ToolTipSpan} from './TooltipStyles';
import {RcSliderStyle} from '../../assets/styles/RCSliderStyle';

import BaseSlider from '../../components/BaseSlider/BaseSlider'
import SliderHandle from '../../components/BaseSlider/SliderHandle'
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';

const NumericalSlider = ({
   title, sub_title, value, defaultValue, min,
   max, marks, step, option1, option2, handPointer,
   prefix, suffix, separator, onChange,
   onAfterChange, center, observation, instruction
  }) => {

  const translation = useContext(LocaleContext);
  const opt1Label = createRef();
  const opt2Label = createRef();

  const applyFormatting = (value) => {
    const evaluatedSuffix = (typeof suffix === 'function') ? suffix(value) : suffix;
    const formattedValue = separator ? numeral(value).format(`0${separator}0`) : value;

    let formatting = '';

    if (prefix) formatting += `${prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;

    return formatting
  };

  const getLabel = (value) => {
    const valueLabel = _get(marks, `${value}.label`);
    let label;

    if (_get(option1, 'label') && value === min) {
      label = opt1Label.current && opt1Label.current.textContent
    } else if (_get(option2, 'label') && value === max) {
      label = opt2Label.current && opt2Label.current.textContent
    } else if (valueLabel) {
      label = valueLabel
    } else {
      label = applyFormatting(value)
    }

    return `${label}`.trim()
  };

  const [tooltipValue, setTooltipValue] = useState(getLabel(value || defaultValue));
  const [sliderValue, setValue] = useState(value || defaultValue);
  const [sliderMoved, setSliderMoved] = useState(false);
  const [opacity, setOpacity] = useState({ left: 0, right: 0 });

  useEffect(() => {
    const decrease = (defaultValue - sliderValue) / (defaultValue * 1.0);
    const increase = (sliderValue - defaultValue) / (defaultValue * 1.0);

    const newOpacity = {
      left: decrease,
      right: increase,
    };

    setOpacity(newOpacity)
  }, [sliderValue]);

	const handleSlide = (value) => {
    setSliderMoved(true);
    onChange(value);
    setValue(value);
    setTooltipValue(getLabel(value));
	};

  const props = {
    onAfterChange,
    onChange: handleSlide,
    max,
    min,
    step,
    marks,
    defaultValue: value || defaultValue,
    handle: SliderHandle,
    key: 'slider'
  };

  const _opt1 = _get(option1, 'label', _get(translation, `${observation}.option1`)) || applyFormatting(min);
  const _opt2 = _get(option2, 'label', _get(translation, `${observation}.option2`)) || applyFormatting(max);

  return <>
    <GlobalBaseStyle />
    <RcSliderStyle />
    <CenterContent center={center}>
      {
        !handPointer && <>
          <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question`)}</Question>
          <Instruction
              visible={instruction}
              alignment={_get(instruction, 'alignment', 'left')}
          >
            {extract_text(instruction) || _get(translation, `${observation}.instruction`, '')}
          </Instruction>
          <Hint
              visible={sub_title} render_as={_get(sub_title, 'as', 'hint')}
              alignment={_get(sub_title, 'alignment', 'left')}
          >
            {extract_text(sub_title) || _get(translation, `${observation}.hint`, _get(translation, `${observation}.subtitle`))}
          </Hint>
        </>
      }
      <SliderBaseContainer pointer={handPointer}>
        <TooltipContainer pointer={handPointer} visible={sliderMoved}>
          <Tooltip>
            <ToolTipValue>
              <ToolTipSpan opacity={opacity.left}>{_opt1}</ToolTipSpan>
              <ToolTipSpan opacity={opacity.right}>{_opt2}</ToolTipSpan>
            </ToolTipValue>
          </Tooltip>
          <TooltipTick position={((sliderValue / max) * 100) - .450} />
        </TooltipContainer>

        <SliderBaseLabel>
          <BaseOption ref={opt1Label} align={'left'}>{_opt1}</BaseOption>
          <BaseOption ref={opt2Label} align={'right'}>{_opt2}</BaseOption>
        </SliderBaseLabel>
        <SliderBase>
          <Slider {...props} />
        </SliderBase>
      </SliderBaseContainer>
    </CenterContent>
  </>
};

NumericalSlider.defaultProps = {
  title: '',
  sub_title: ''
};

export default BaseSlider(NumericalSlider);
