import React, { useState, useEffect,useContext } from 'react';
import DateSelector from '../../components/DateSelector/DateSelector';
import { CenterContent, GlobalBaseStyle, Question, Hint } from '../../assets/styles/BaseStyle';
import { extract_text } from '../../helpers';
import _get from "lodash.get";
import { LocaleContext } from "../../context/LocalContext";
const ComboBox = ({ label, preset, center, help, defaultValue, value, onChangeHandler, observation, title, handPointer, hide_hands, isOpen, handTarget, sub_title, helpText, previousValues }) => {
  const [type, setType] = useState(null);
  const translation = useContext(LocaleContext);
  const dataPickDetails = preset.meta;
  dataPickDetails.labels = preset.meta.labels || _get(translation, `${observation}.labels`)
  const preset_map = {
    'date_picker': () => <DateSelector
      {...preset.meta}
      defaultValue={defaultValue}
      help={help}
      value={value}
      onChangeHandler={onChangeHandler}
      handPointer={handPointer}
      handTarget={handTarget}
      observation={observation}
      previousValues={previousValues}
      hide_hands={hide_hands}
      isOpen={isOpen}
    />
  };

  useEffect(() => {
    if (preset_map[preset.type]) {
      setType(preset_map[preset.type](preset || {}))
    }
  }, [observation, isOpen]);

  return <>
    <GlobalBaseStyle />
    <CenterContent center={center}>    
      <div className='col-sm-12 col-md-8 col-lg-8 mx-auto p-0'>
        <div className="px-3">
          {
            handPointer && <>
            <div> { _get(translation, `${observation}.${helpText}`) }</div>
            </>
          }
          {
            !handPointer && <>
            <Question alignment={title.alignment}>{extract_text(title)|| _get(translation, `${observation}.title`)}</Question>
            </>
          } 
          <Hint visible={sub_title} render_as={sub_title.as} alignment={sub_title.alignment}>{extract_text(sub_title)}</Hint>
        </div>
        <label className={`lefl-form-label text-center mb-4 formLabel`}>
          {label}
        </label>
        {type}
      </div>
    </CenterContent>
  </>
};

ComboBox.defaultProps = {
  sub_title: '',
  title: ''
};

export default ComboBox;
