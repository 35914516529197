import React, { Fragment, useContext, useEffect, useState } from "react";

import { GlobalBaseStyle } from "../../assets/styles/BaseStyle";
import { CenteredText, BoxContainer, Option } from "./ChoiceBoxStyle";
import { extract_text } from "../../helpers";

import getSymbolFromCurrency from "currency-symbol-map";
import { LocaleContext } from "../../context/LocalContext";
import _get from "lodash.get";

import numeral from "numeral";

const ChoiceBoxScreen = ({
  observation, title, sub_title, label_1, label_2, suffix, prefix, value_1, value_2, generate,
  onChangeHandler, handleNavigation, previousValues, handPointer, is_first, v2
}) => {
  const [clicked, setClicked] = useState(false);
  const [factorA, setSactorA] = useState(value_1);
  const translation = useContext(LocaleContext);
  useEffect(() => {
    const generateScreenTimeout = _get(generate, "delay");
    const ob = _get(generate, "prev_ob");
    const prevSelect = _get(previousValues, ob, "");
    const del = _get(generate, "delta", 0);
    const { v1 } = choiceFormula(
      del,
      parseInt(factorA),
      parseInt(value_2),
      _get(prevSelect, "label")
    );
    setSactorA(v1);
    setClicked(false);
    if (typeof generateScreenTimeout === "number") {
      setTimeout(() => {
        if (!clicked && !handPointer)  onChangeHandler({ response_options: [v1.toString(),value_2], label: _get(translation, `${observation}.label_1`) || `option_1`, value:value_1 });
      }, generateScreenTimeout);
    }
  }, [observation]);

  useEffect(() => {
    if (clicked) handleNavigation(1);
  }, [clicked]);

  const applyFormatting = (value) => {
    const evaluatedSuffix =
      typeof suffix === "function" ? suffix(value) : suffix;
    const formattedValue = numeral(value).format(`0,0`);

    let formatting = "";

    if (prefix) formatting += `${getSymbolFromCurrency(prefix) || prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;
    return formatting;
  };

  const choiceMade = (v, l,i) => {
    if (typeof onChangeHandler === "function") {
      setClicked(true);
      onChangeHandler({ value: v.toString(), label: l, response_options: [factorA.toString(),value_2] });
    }
  };

  const choiceFormula = (d, v_1, v_2, select) => {
    if (d === 0) {
      return { v1: value_1, v2: v_2 };
    }
    if (select === "option_1") {
      return { v1: v_1 - d, v2: v_2 };
    } else {
      return { v1: v_1 + d, v2: v_2 };
    }
  };

  return (
    <Fragment>
      <GlobalBaseStyle />
      <div className="row">
        <div className="col-12">
          <CenteredText>
            {extract_text(title) || _get(translation, `${observation}.title`)}
          </CenteredText>
        </div>
        <div className="col-12">
          <div className={`text-center`}>
            {extract_text(sub_title) ||
              _get(translation, `${observation}.sub_title`)}
          </div>
        </div>
        <div className="col-12">
          <BoxContainer>
            <Option pointer={is_first && handPointer} onClick={() => handPointer ||
              choiceMade(factorA, _get(translation, `${observation}.label_1`),value_1)}>
              <Icon value={applyFormatting(factorA)} />
              <span>
                {extract_text(label_1) ||
                  _get(translation, `${observation}.label_1`, 'option_1')}
              </span>
            </Option>
            <Option pointer={!is_first && handPointer} onClick={() => handPointer ||
              choiceMade(value_2, _get(translation, `${observation}.label_2`, 'option_2'),value_2)}>
              <Icon value={applyFormatting(value_2)} />
              <span>
                {extract_text(label_2) ||
                  _get(translation, `${observation}.label_2`)}
              </span>
            </Option>
          </BoxContainer>
        </div>
      </div>
      <div className="row"></div>
    </Fragment>
  );
};
ChoiceBoxScreen.defaultProps = {
  prefix: "USD",
  suffix: "",
};
const Icon = ({ value }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 240 135" style={{ enableBackground: 'new 0 0 136.5 77.21' }} >
    <style>{`
          .st0{fill:#B9EF85;}
          .st1{fill:#60A15C;}
          .st2{fill:none;stroke:#A0CB64;stroke-miterlimit:10;}
          .st3{fill:none;stroke:#A0CB64;stroke-width:2;stroke-miterlimit:10;}
          .st4{fill:none;stroke:#A0CB64;stroke-width:5.7976;stroke-miterlimit:10;}
          .st5{fill:none;stroke:#A0CB64;stroke-width:5.8132;stroke-miterlimit:10;}
          .st6{fill:#FFFFFF;stroke:#A0CB64;stroke-width:1.2181;stroke-miterlimit:10;}
        `}</style>
    
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0H239.5V135H0.5V0ZM194.799 67.4912C194.799 103.105 161.31 131.975 120 131.975C78.6896 131.975 45.2009 103.105 45.2009 67.4912C45.2009 31.8777 78.6896 3.00733 120 3.00733C161.31 3.00733 194.799 31.8777 194.799 67.4912Z" fill="#B9EF85"/>
    <path d="M173.824 112.27H199.314L199.543 111.742C202.92 103.922 209.183 97.6848 217.016 94.2932L217.544 94.065V40.9339L217.015 40.7061C209.184 37.3329 202.938 31.0796 199.542 23.2567L199.313 22.7302H173.843C174.484 23.303 175.113 23.8859 175.729 24.4787H198.169C201.711 32.2858 207.973 38.5563 215.793 42.0787V92.9224C207.975 96.4589 201.695 102.712 198.168 110.521H175.71C175.094 111.114 174.465 111.697 173.824 112.27Z" fill="#A0CB64"/>
    <path d="M66.1763 112.27C65.5351 111.697 64.906 111.114 64.2895 110.521H41.8133C38.2719 102.714 32.0097 96.4437 24.1899 92.9213V42.0776C32.0078 38.5411 38.2871 32.2877 41.8144 24.4787H64.2712C64.8873 23.8859 65.5159 23.303 66.1567 22.7302H40.668L40.4399 23.2582C37.062 31.0779 30.7999 37.3152 22.9662 40.7068L22.439 40.935V94.0661L22.9677 94.2939C30.7983 97.6671 37.0443 103.92 40.4406 111.743L40.6692 112.27H66.1763Z" fill="#A0CB64"/>
    <path d="M153.635 125.104L230.395 125.103V9.89624H153.675C159.144 12.2777 164.228 15.2212 168.825 18.6386H202.195C205.068 27.8874 212.379 35.1882 221.64 38.0571V96.9426C212.379 99.8116 205.068 107.112 202.195 116.361H168.802C164.2 119.779 159.11 122.723 153.635 125.104Z" fill="#60A15C"/>
    <path d="M86.3649 125.103C80.8894 122.723 75.7996 119.779 71.1978 116.361H37.8101C34.9409 107.104 27.6265 99.7959 18.3591 96.9251V38.0571C27.6207 35.1882 34.9317 27.8874 37.8046 18.6386H71.1746C75.7713 15.2212 80.8555 12.2777 86.3251 9.89624H9.60449V125.103L86.3649 125.103Z" fill="#60A15C"/>
    <path d="M194.027 76.7908C194.536 73.7542 194.799 70.6492 194.799 67.4912C194.799 64.3332 194.536 61.2282 194.027 58.1917C195.072 57.8166 196.198 57.6123 197.373 57.6123C202.836 57.6123 207.265 62.0352 207.265 67.4912C207.265 72.9472 202.836 77.3701 197.373 77.3701C196.198 77.3701 195.072 77.1658 194.027 76.7908Z" fill="#60A15C"/>
    <path d="M45.9732 58.1917C45.4644 61.2283 45.2011 64.3332 45.2011 67.4912C45.2011 70.6492 45.4644 73.7542 45.9732 76.7907C44.9281 77.1657 43.8015 77.3701 42.627 77.3701C37.1635 77.3701 32.7344 72.9472 32.7344 67.4912C32.7344 62.0352 37.1635 57.6123 42.627 57.6123C43.8015 57.6123 44.9281 57.8167 45.9732 58.1917Z" fill="#60A15C"/>
    <path d="M187.936 40.4714H197.373V30.3071H181.118C183.732 33.4939 186.018 36.8941 187.936 40.4714Z" fill="#A0CB64"/>
    <path d="M190.342 89.4696C190.826 88.3189 191.273 87.1529 191.682 85.9727H210.768V89.4696H190.342Z" fill="#A0CB64"/>
    <path d="M180.931 104.903C183.567 101.718 185.875 98.3174 187.814 94.7383H197.373V104.903H180.931Z" fill="#A0CB64"/>
    <path d="M58.8704 30.3208C56.2649 33.4996 53.9846 36.8906 52.0713 40.4578H42.627V30.3208H58.8704Z" fill="#A0CB64"/>
    <path d="M48.3183 85.9727C48.7272 87.1529 49.1741 88.3189 49.658 89.4696H29.2324V85.9727H48.3183Z" fill="#A0CB64"/>
    <path d="M52.1939 94.752C54.1279 98.3209 56.4299 101.712 59.0577 104.889H42.627V94.752H52.1939Z" fill="#A0CB64"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120 126.975C159.271 126.975 189.799 99.6727 189.799 67.4912C189.799 35.3097 159.271 8.00732 120 8.00732C80.7292 8.00732 50.2012 35.3097 50.2012 67.4912C50.2012 99.6727 80.7292 126.975 120 126.975ZM194.799 67.4912C194.799 103.105 161.311 131.975 120 131.975C78.6899 131.975 45.2012 103.105 45.2012 67.4912C45.2012 31.8777 78.6899 3.00732 120 3.00732C161.311 3.00732 194.799 31.8777 194.799 67.4912Z" fill="#B9EF85"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M120 9.05536C82.3172 9.05536 52.0454 35.3595 52.0454 67.4912C52.0454 99.6228 82.3172 125.927 120 125.927C157.683 125.927 187.955 99.6228 187.955 67.4912C187.955 35.3595 157.683 9.05536 120 9.05536ZM49.9126 67.4912C49.9126 33.9001 81.4444 6.92554 120 6.92554C158.556 6.92554 190.088 33.9001 190.088 67.4912C190.088 101.082 158.556 128.057 120 128.057C81.4444 128.057 49.9126 101.082 49.9126 67.4912Z" fill="#A0CB64"/>

    <text x="50%" y="60%" textAnchor="middle" style={{fontSize:"xx-large"}}>{value}</text>
    </svg>
);

export default ChoiceBoxScreen;
