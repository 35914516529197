import styled from 'styled-components';


export const QuestionImage = styled.div`

${({ handPointer }) => handPointer && `

.QuestionImage{
    width: 50% !important;
}

`}
text-align:center;
margin-top:10px;
@media (max-width:575px) and (min-width:320px){
    .QuestionImage{
    width: ${({ v2 }) => v2 ? '78%' : '47%'} !important;
    }
}
@media (max-width:900px) and (min-width:576px){
    .QuestionImage{
    width: 42% !important;
    }
}
.QuestionImage{
    width: 275px;
}
`;


export const Radio = styled.div`
    width: 45%;
    margin: auto;
    padding: auto 25px;
@media (max-width:575px) and (min-width:320px){
    width:90%;
}
@media (max-width:900px) and (min-width:576px){
    width:61%;
}
`;


export const Img = styled.div`
width: 8%;
display: 'flex';
justify-content: 'center';
margin: 0px 6px;
@media (max-width:575px) and (min-width:320px){
    width:${({ v2 }) => v2 ? '18%' : '15%'};
    .labelFont{
        font-size:10px;
    }
}
${({ v2 }) => v2 && `margin:0px;`}

@media (max-width:900px) and (min-width:576px){
    width:14%;
}
`;

export const ImgWraper = styled.div`
display:flex;
justify-content:center;
margin-top:${({ v2 }) => !v2 ? '10px' : '25px'};
text-align:center;
p{
    margin:0px;
}
img{
    width: 100%;
}
@media (max-width:575px) and (min-width:320px){
    margin-top:-10px;
}

`;



