import React from 'react'

import { Handle } from './BaseSliderStyle'

const SliderHandle = (props) => {
    const { value, dragging, ...restProps } = props;
    return <Handle value={value} {...restProps} active={dragging.toString()} prefixCls={''} >
        <svg style={{ width: "20px", height: "15px" }} viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8438 1.46875C11.6875 1.625 11.6875 1.875 11.8438 2L14.125 4.1875H3.84375L6.125 2C6.28125 1.875 6.28125 1.625 6.125 1.46875L5.5 0.875C5.375 0.71875 5.125 0.71875 4.96875 0.875L1.09375 4.75C0.9375 4.90625 0.9375 5.125 1.09375 5.28125L5 9.15625C5.125 9.3125 5.375 9.3125 5.5 9.15625L6.125 8.5625C6.28125 8.40625 6.28125 8.15625 6.125 8L3.84375 5.8125H14.125L11.8438 8C11.6875 8.15625 11.6875 8.40625 11.8438 8.5625L12.4688 9.15625C12.5938 9.3125 12.8438 9.3125 13 9.15625L16.875 5.28125C17.0312 5.125 17.0312 4.90625 16.875 4.75L13 0.875C12.8438 0.71875 12.5938 0.71875 12.4688 0.875L11.8438 1.46875Z"
                fill="white" />
        </svg>
    </Handle>
};

export default SliderHandle