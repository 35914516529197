import styled from "styled-components";

export const CenteredText = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const GameInstructionsImage = styled.div`
  margin: 0 auto;

  width: ${({ width }) => width}%;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
  }
`;

export const LeftImage = styled.div`
  img {
    max-width: 50%;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    img {
      max-width: 100%;
    }
  }
`;

export const RightImage = styled.div`
  img {
    max-width: 200%;
    @media (min-width: 320px) and (max-width: 480px) {
      img {
        max-width: 100%;
      }
    }
  }
`;
export const NotMobile = styled.div`
  display: block;
  text-align: center;
  margin: 0 auto;
  img {
    margin: 0 auto;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const Mobile = styled.div`
  display: block;
  text-align: center;
  margin: 0 auto;
  img {
    max-width: 100%;
  }
  @media screen and (min-width: 600px) {
    display: none;
    img {
     margin: 50px auto;
    }
  }
  @media (min-width:320px) and (max-width: 425px) {
    img {
     margin: 50px auto;
    }
  }
`;
