// eslint-disable-next-line import/no-anonymous-default-export
export default {
  screens: [
    {
      observation: "si_intro",
      type: "intro",
      module: "self_image",
      meta: {
        v2: true,
        hiddenHeader: true,
        hideBottumNavigation: true,
        image: "self_imgs/intro_selfimage",
      },
      auto_advance: true,
      timer: {
        time: 10000,
        extend: false,
        pause: false,
        visible: false,
      },
    },
    {
      observation: "conscientiousness_org1",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideBottumNavigation: true,
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/conscientiousness_org1Img1",
          "self_imgs/conscientiousness_org1Img2",
        ],
        optionValue: ["Messy", "Tidy"],
      },
    },
    {
      observation: "conscientiousness_org2",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        valueVisible: false,
        hideBottumNavigation: true,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/conscientiousness_org2Img1",
          "self_imgs/conscientiousness_org2Img2",
          "self_imgs/conscientiousness_org2Img3",
          "self_imgs/conscientiousness_org2Img4",
        ],
        optionValue: ["Messy", "Tidy", "Very Tidy", "Insanely Tidy"],
      },
    },
    {
      observation: "conscientiousness_highachieving",
      module: "self_image",
      type: "find_pattern",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        valueVisible: false,
        v2: true,
        variant: false,
        hideHalpNavigation: true,
        hideBottumNavigation: true,
        helpImgs: ["self_imgs/conscientiousness_highachievingHelpImg1"],
        title: { alignment: "center" },
        questionImage: "self_imgs/conscientiousness_highachievingImg",
        options: [
          {
            img: "self_imgs/conscientiousness_highachievingImg1",
            label: "Very Satisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg2",
            label: "Satisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg3",
            label: "Neutral",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg4",
            label: "Unsatisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg5",
            label: "Very Unsatisfied",
          },
        ],
        "optionValue": [
          {
            "label": "Very Satisfied"
          },
          {
            "label": "Satisfied"
          },
          {
            "label": "Neutral"
          },
          {
            "label": "Unsatisfied"
          },
          {
            "label": "Very Unsatisfied"
          }
        ]
      },
    },
    {
      observation: "conscientiousness_plan1",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/conscientiousness_plan1Img1",
          "self_imgs/conscientiousness_plan1Img2",
          "self_imgs/conscientiousness_plan1Img3",
          "self_imgs/conscientiousness_plan1Img4",
        ],
        optionValue: [
          "5 minutes late",
          "10 minutes late",
          "15 minutes late",
          "20 minutes late",
        ],
      },
    },
    {
      observation: "financialsituation1",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        valueVisible: false,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/financialsituation1Img1",
          "self_imgs/financialsituation1Img2",
          "self_imgs/financialsituation1Img3",
          "self_imgs/financialsituation1Img4",
          "self_imgs/financialsituation1Img5",
          "self_imgs/financialsituation1Img6",
        ],
        optionValue: [
          "Very little money",
          "Some money",
          "Modest amount",
          "Considerable sum",
          "Huge amount",
          "Vast sum",
        ],
      },
    },
    {
      observation: "conscientiousness_plan3",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        generate: {
          delay: 14999,
        },
        title: {
          alignment: "left",
        },
        hideBottumNavigation: true,
        valueVisible: true,
        hideHalpNavigation: true,
        option: [
          "self_imgs/conscientiousness_plan3Img1",
          "self_imgs/conscientiousness_plan3Img2",
        ]
      },
    },
    {
      observation: "conscientiousness_plan4",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/conscientiousness_plan4Img1",
          "self_imgs/conscientiousness_plan4Img2",
        ],
        optionValue: ["Spontaneous", "Daily Planning"],
      },
    },
    {
      observation: "lifesatisfaction1",
      module: "self_image",
      type: "find_pattern",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        valueVisible: false,
        hideBottumNavigation: true,
        v2: true,
        variant: false,
        hideHalpNavigation: true,
        helpImgs: ["self_imgs/lifesatisfaction1help1"],
        title: { alignment: "center" },
        options: [
          {
            img: "self_imgs/conscientiousness_highachievingImg1",
            label: "Very Satisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg2",
            label: "Satisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg3",
            label: "Neutral",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg4",
            label: "Unsatisfied",
          },
          {
            img: "self_imgs/conscientiousness_highachievingImg5",
            label: "Very Unsatisfied",
          },
        ],
        "optionValue": [
          {
            "label": "Very Satisfied"
          },
          {
            "label": "Satisfied"
          },
          {
            "label": "Neutral"
          },
          {
            "label": "Unsatisfied"
          },
          {
            "label": "Very Unsatisfied"
          }
        ]
      },
    },
    {
      observation: "financialsituation2",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        title: {
          alignment: "left",
        },
        hideBottumNavigation: true,
        // valueVisible: true,
        option: [
          "self_imgs/financialsituation1Img1",
          "self_imgs/financialsituation1Img2",
          "self_imgs/financialsituation1Img3",
          "self_imgs/financialsituation1Img4",
          "self_imgs/financialsituation1Img5",
          "self_imgs/financialsituation1Img6",
        ],
        optionValue: [
          "Very little money",
          "Some money",
          "Modest amount",
          "Considerable sum",
          "Huge amount",
          "Vast sum",
        ],
      },
    },
    {
      observation: "locusofcontrol6",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        title: {
          alignment: "left",
        },
        hideBottumNavigation: true,
        valueVisible: true,
        option: [
          "self_imgs/locusofcontrol6Img1",
          "self_imgs/locusofcontrol6Img2",
        ],
        optionValue: ["My co-workers", "Myself"],
      },
    },
    {
      valueVisible: false,
      observation: "conscientiousness_plan5",
      module: "self_image",
      type: "find_pattern",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        valueVisible: false,
        v2: true,
        variant: false,
        hideBottumNavigation: true,
        hideHalpNavigation: true,
        helpImgs: ["self_imgs/conscientiousness_plan5help1"],
        title: { alignment: "center" },
        options: [
          { img: "self_imgs/conscientiousness_plan5Img1", label: "20%" },
          { img: "self_imgs/conscientiousness_plan5Img2", label: "40%" },
          { img: "self_imgs/conscientiousness_plan5Img3", label: "60%" },
          { img: "self_imgs/conscientiousness_plan5Img4", label: "80%" },
          { img: "self_imgs/conscientiousness_plan5Img5", label: "100%" },
        ],
        "optionValue": [
          {
            "label": "20%"
          },
          {
            "label": "40%"
          },
          {
            "label": "60%"
          },
          {
            "label": "80%"
          },
          {
            "label": "100%"
          }
        ]
      },
    },
    // {
    //   observation: "lifesatisfaction5",
    //   module: "self_image",
    //   type: "pick_img",
    //   help: true,
    //   auto_advance: true,
    //   timer: {
    //     time: 15000,
    //     extend: true,
    //     pause: false,
    //     visible: true,
    //   },
    //   meta: {
    //     hideHalpNavigation: true,
    //     generate: {
    //       delay: 14999,
    //     },
    //     title: {
    //       alignment: "left",
    //     },
    //     hideBottumNavigation: true,
    //     // valueVisible: true,
    //     option: [
    //       "self_imgs/lifesatisfaction5Img1",
    //       "self_imgs/lifesatisfaction5Img2",
    //       "self_imgs/lifesatisfaction5Img3",
    //       "self_imgs/lifesatisfaction5Img4",
    //     ],
    //     optionValue: [
    //       "Small house",
    //       "House with porch",
    //       "Split-level house",
    //       "Large contemporary house",
    //     ],
    //   },
    // },
    {
      observation: "lifesatisfaction3",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        hideBottumNavigation: true,
        option: [
          "self_imgs/lifesatisfaction3Img1",
          "self_imgs/lifesatisfaction3Img2",
          "self_imgs/lifesatisfaction3Img3",
          "self_imgs/lifesatisfaction3Img4",
          "self_imgs/lifesatisfaction3Img5",
          "self_imgs/lifesatisfaction3Img6",
        ],
        optionValue: ["Hopeful", "Unhappy", "Joyful", "Sad", "Angry", "Happy"],
      },
    },
    {
      observation: "lifesatisfaction4",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        title: {
          alignment: "left",
        },
        hideBottumNavigation: true,
        valueVisible: true,
        option: [
          "self_imgs/lifesatisfaction4Img1",
          "self_imgs/lifesatisfaction4Img2",
          "self_imgs/lifesatisfaction4Img3",
          "self_imgs/lifesatisfaction4Img4",
        ]
      },
    },
    {
      observation: "honesty2",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        hideHalpNavigation: true,
        title: {
          alignment: "left",
        },
        valueVisible: true,
        option: [
          "self_imgs/honesty2Img1",
          "self_imgs/honesty2Img2",
          "self_imgs/honesty2Img3",
          "self_imgs/honesty2Img4",
          "self_imgs/honesty2Img5",
          "self_imgs/honesty2Img6",
        ],
      },
    },
    {
      observation: "financialsituation3",
      type: "group_image_button",
      module: "self_image",
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      auto_advance: true,
      help: true,
      meta: {
        title: {
          alignment: "left",
          text: "What do you use your phone for?  (Select all that apply)",
        },
        generate: {
          delay: 14999,
        },
        center: true,
        hideHalpNavigation: true,
        valueVisible: true,
        helpImgs: ["self_imgs/financialsituation3help1"],
        options: [
          { image: "self_imgs/financialsituation3Img1", label: "Call" },
          { image: "self_imgs/financialsituation3Img2", label: "Whatsapp" },
          { image: "self_imgs/financialsituation3Img3", label: "Picture" },
          { image: "self_imgs/financialsituation3Img4", label: "Social media" },
          { image: "self_imgs/financialsituation3Img5", label: "Email" },
          { image: "self_imgs/financialsituation3Img6", label: "Google" },
          { image: "self_imgs/financialsituation3Img7", label: "Health apps" },
          {
            image: "self_imgs/financialsituation3Img8",
            label: "Financial apps",
          },
          { image: "self_imgs/financialsituation3Img9", label: "Games" },
        ],
      },
      next: {
        rules: {
          visible: true,
          enabled: ["itsDone"],
        },
        visible: true,
        enabled: false,
      },
    },
    {
      observation: "openness3",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        option: ["self_imgs/openness3Img1", "self_imgs/openness3Img2"],
        optionValue: ["Non-tech savvy", "Tech savvy"],
      },
    },
    {
      observation: "optimism1",
      type: "simple_slider",
      module: "self_image",
      help: true,
      meta: {
        min: 1,
        max: 100,
        marks: {},
        defaultValue: 50,
        options: [{}, {}, {}],
        center: true,
      },
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      next: {
        rules: {
          visible: true,
          enabled: ["observation"],
        },
        // valueVisible: true,
        visible: true,
        enabled: false,
      },
    },
    {
      type: "radio_group",
      observation: "extraversion3",
      module: "self_image",
      meta: {
        title: {
          alignment: "left",
        },
        center: true,
        options: [
          {
            value: 1,
          },
          {
            value: 2,
          },
          {
            value: 3,
          },
          {
            value: 4,
          },
          {
            value: 5,
          },
        ],
        shuffle: false
      },
      help: true,
      next: {
        rules: {
          visible: true,
          enabled: ["observation"],
        },
        visible: true,
        enabled: false,
      },
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      }
    },
    {
      observation: "openness2",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        // valueVisible: true,
        title: {
          alignment: "left",
        },
        option: [
          "self_imgs/openness2Img1",
          "self_imgs/openness2Img2",
          "self_imgs/openness2Img3",
          "self_imgs/openness2Img4",
        ],
        optionValue: ["Eager to change", "Unwilling", "Indifferent", "Worried"],
      },
    },
    {
      observation: "honesty3",
      module: "self_image",
      type: "hover_slider",
      help: true,
      auto_advance: true,
      meta: {
        title: { alignment: "left" },
        maxImgs: Array.from({ length: 10 }, () => false),
        helpImgs: "self_imgs/honesty3help1",
        hideHalpNavigation: true,
      },
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      next: {
        rules: {
          visible: true,
          enabled: ["observation"],
        },
        visible: true,
        enabled: false,
      },
    },
    {
      observation: "locusofcontrol1",
      module: "self_image",
      type: "pick_img",
      help: true,
      auto_advance: true,
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      meta: {
        hideHalpNavigation: true,
        generate: {
          delay: 14999,
        },
        hideBottumNavigation: true,
        title: {
          alignment: "left",
        },
        valueVisible: true,
        option: [
          "self_imgs/locusofcontrol1Img1",
          "self_imgs/locusofcontrol1Img2",
          "self_imgs/locusofcontrol1Img3",
          "self_imgs/locusofcontrol1Img4",
          "self_imgs/locusofcontrol1Img5",
          "self_imgs/locusofcontrol1Img6",
        ],
        optionValue: [
          "Hard Work",
          "Rich family",
          "Network",
          "Cheating",
          "Luck",
          "Intelligence",
        ],
      },
    },
    {
      observation: 'rotation_page',
      module: 'self_image',
      type: 'radio_group',
      help: true,
      auto_advance: true,
      meta: {
        image: 'rotation_puzzle',
        center: true,
        title: {
          alignment: 'left'
        },
        options: [{
          value: 1
        }, {
          value: 2
        },
        {
          value: 3
        }],
      },
      timer: {
        time: 15000,
        extend: true,
        pause: false,
        visible: true,
      },
      next: {
        rules: {
          visible: true,
          enabled: ['observation']
        },
        visible: true,
        enabled: false
      },
    },
    {
      observation: "greatJob",
      type: "module_finish",
      module: "self_image",
      auto_advance: true,
      next: {
        visible: true,
        enabled: true
      },
      timer: {
        time: 3000,
        extend: false,
        pause: false,
        visible: false,
      },
    },
  ],
  screen_submit: true
};
