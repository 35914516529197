import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {Input, InputContainer} from './TextInputStyles';
import {Warning, FormLabel} from '../../assets/styles/BaseStyle';

const InputField = ({
  autoFocus, question, hint, validation, onChangeHandler,
  onBlurHandler, value, defaultValue, data_type, handPointer, hide_hands,
  observation, allowPaste, question_alignment, key_name, currency }) => {

  const [input_value, setInputValue] = useState(value || defaultValue || '');
  const [blur_state, setBlurState] = useState(false);
  const [validation_message, setValidationMessage] = useState(null);
  const [is_input_touched, setInputTouchStatus] = useState(false);
  const [input_error, setInputErrorState] = useState(false);

  useEffect(() => {
    setInputValue(value || defaultValue || '')
  }, [observation]);

  useEffect(() => {
    if (blur_state) {
      if (typeof onBlurHandler === 'function') onBlurHandler(input_value, observation, input_error)
    }
  }, [blur_state]);

  const validateInput = (value, data_type) => {
    let length_rule = true, validation_rule = true;

    if (validation && (validation.max || validation.min)) {
      let target = value.length;
      if (data_type === 'number') target = value;

      if (validation.max && validation.min) {
        length_rule = target >= validation.min && target <= validation.max;
      } else if (validation.max) {
        length_rule = target <= validation.max;
      } else if (validation.min) {
        length_rule = target >= validation.min
      }
    }

    if (validation && validation.rule && validation.rule.length > 0) {
      const validator = new RegExp(validation.rule);
      validation_rule = validator.test(value);
    }

    return length_rule && validation_rule
  };
  const formatNumber = (n) => {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  
  
  const formatCurrency = (input_val,symbl) =>{
    
    
    // don't validate empty input
    if (input_val === "") { 
      input_val = symbl + 0; 
      return input_val;
    }
    
    // check for decimal
    if (input_val.indexOf(".") >= 0) {
  
      let decimal_pos = input_val.indexOf(".");
  
      // split number by decimal point
      let left_side = input_val.substring(0, decimal_pos);
      let right_side = input_val.substring(decimal_pos);
      // add commas to left side of number
      left_side = formatNumber(left_side);
  
      // validate right side
      right_side = formatNumber(right_side);
    
      
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
  
      // join number by .
      input_val = symbl + left_side + "." + right_side;
  
    } else {
      
      input_val = formatNumber(input_val);
      if (input_val === "") { 
        input_val = 0;
      }
      input_val = symbl + input_val;
    }
    return input_val;
  }
  const inputHandler = (e) => {
    setBlurState(false);

    const value = currency ? formatCurrency(e.target.value,currency.symbl):e.target.value;
    const is_valid = validateInput(value, data_type);
    const is_empty = validation && validation.empty && value.length <= 0;
    const has_error = (!is_valid && value) || is_empty;
    if (has_error) {
      const empty_message = validation && (typeof validation.empty === 'string' && validation.empty) || null;
      const invalid_message = validation && (typeof validation.message === 'string' && validation.message) || empty_message || 'Invalid input!';

      setValidationMessage((is_empty && empty_message) || (!is_valid && invalid_message));
    } else {
      setValidationMessage(null);
    }

    setInputValue(value);
    setInputTouchStatus(true);
    setInputErrorState(Boolean(has_error));
    if (typeof onChangeHandler === 'function') onChangeHandler(value, observation, Boolean(has_error))
  };

  const textInput = {
    id: `textInput${key_name}`,
    placeholder: handPointer ? '' : hint,
    className: 'form-control',
    type: 'text',
    autoComplete: 'off',
    value: input_value,
    onChange: inputHandler,
    onBlur: () => setBlurState(true),
    autoFocus: autoFocus,
    onPaste: (e) => !allowPaste && e.preventDefault()
  };

  return <>
    {
      !handPointer && <FormLabel className={`lefl-form-label`} htmlFor="textInput" alignment={question_alignment}>{question}</FormLabel>
    }
    <InputContainer pointer={handPointer} hide_hands={hide_hands}><Input {...textInput} type={data_type}/></InputContainer>
    <Warning visible={validation_message}><span className="lefl-icon-font_icons-validation-warning" />{validation_message}</Warning>
  </>
};

InputField.defaultProps = {
  center: true,
  data_type: 'text',
  allowPaste: true
};

export default InputField;
