import styled from 'styled-components'

import hand from '../../assets/images/hand.svg'

import Slider from 'rc-slider'

const Handler = Slider.Handle;

export const SliderBaseContainer = styled.div`
  margin:60px 15px;
  
  ${({ pointer }) => pointer && `
  [class~="handle"]:after {
		content: '';
		display: flex;
		width: 70px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 3px;
		background: url(${hand}) center center no-repeat;
		z-index: 1;
	}
  `}
`;

export const SliderBase = styled.div`
	padding: 15px 0;
	position: relative;

	[class~="rc-slider"] {
		cursor: pointer;
	}

	[class="rc-slider-mark"] {
		display: none;
	}

	[class~="rc-slider-rail"] {
		border-radius: 0;
		background: #BFBFBF;
	}

	[class~="rc-slider-track"] {
		background: transparent;
	}

	[class~="rc-slider-dot"] {
		background: #BFBFBF;
		border: 0px;
		border-radius: 0;
		height: 10px;
		width: 2px;
		bottom: 0;
		margin: 0;
	}
`;

export const SliderBaseBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 20px;
  left: 0;
  bottom: 23px;
`;

export const SliderBaseLabel = styled.div`
  display: flex;
	justify-content: space-between;
	margin: 10px 0;
`;

export const BaseOption = styled.div`
  max-width: 50%;
  text-align: ${props => props.align};
`;

export const BaseToolTip = styled.div`
  display: flex;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
	justify-content: center;
	align-items: center;
	margin: 15px 0;

	span {
		margin: 0 5px;
	}
`;

export const BaseToolTipValue = styled.span`
  border-radius: 20px;
  display: inline-block;
  padding: 10px 25px;
  background-color: #DCE2E8;
  color: #333;
  text-align: center;
`;

export const Handle = styled(Handler)`
	position: absolute;
	border-radius: 50% !important;
	border: none !important;
	width: 30px !important;
	height: 30px !important;
	margin-left: -15px !important;
	margin-top: -15px !important;
	transform: translateX(0%)!important;

	outline: none !important;
	box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0,		 0, 0.4) !important;
	cursor: pointer !important;

	${({ dragging }) => dragging && `
		box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3) !important;
		border: 0 !important; 
	`}
	display:flex;
	justify-content:center;
	align-items:center;

	background: ${({ theme }) => theme.colors.primary} !important;
`