import styled from 'styled-components';
import hand from '../../assets/images/hand.svg';

export const ContentWrapper = styled.div`
display:flex;

.int_input{
    height: 40px;
    width: 78%;
    left: 0px;
    top: 0px;
    border-radius: 20px;
    background-color:#F5F5F5;
    border:1px solid #EEEEEE;
    box-shadow: none;
    text-indent:20px;
    outline:none;
}
.dummySt {
    width: 69%;
}
.circle {
    height: 40px;
    width: 40px;
    font-size: 25px;
    border-radius: 30px;
    border: 0px solid rgb(238, 238, 238);
    margin: auto;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-content: center;
    display: flex;
    cursor: pointer;
    outline: none;
    color: rgb(153, 153, 153);
    padding: 0;
    background-color:#F5F5F5;
    &:active{
        background-color: #ffffff;
    }
}
    @media (max-width:575px) and (min-width:320px){
        .int_input{
            width:66% !important;
            margin-right:8px !important;
        }
        .circle{
            margin:auto 5px !important;
            width:35px !important;
            height:35px !important;
            line-height: 35px;
            font-size:18px !important;
        }
    }
    @media (max-width:767px) and (min-width:576px){
        .int_input{
            width:66% !important;
            margin-right:8px !important;
        }
        .circle{
            margin:auto 10px !important;
        }
    }

`;

export const DummyModal = styled.div`
    margin-top: 15px;
    margin-bottom: 100px;
`;

export const Option = styled.div`
  position: relative;
  width: 100%;
  background-color: #F0F3F7;
  margin-top: .625em;
  margin-bottom: 0;
  display: flex;
  flex-direction: ${({ type }) => type === 'image' ? 'column' : 'row'};
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  border-radius: 4px;
  
  @media (max-width:767px) and (min-width:320px){
    :after {
        right: 7px !important;
    }
  }
  :after {
    ${({ pointer, theme }) => pointer && `
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      z-index: 1;
    `}
    
    top: 26px;
    right: 20px;
    ${({ hide_hands }) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}
    path: { fill: orange; }
  }
`;