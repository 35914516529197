import styled from 'styled-components';
export const Image = styled.div`
	img {
		width: 100%;
		max-width: 250px;
		max-height: 250px;
		margin: 0px auto 20px;
    display: block;
	}
 
`;
export const Group = styled.div`
display: flex;
flex-wrap: wrap;
  text-align: left;
`;


export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: calc(100%/3);
  margin: 0 0% 20px;
  padding: 10px;
  label{
    font-size:14px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;