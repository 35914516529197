import styled from "styled-components";
export const TextDiv = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;  
  img{
      width: 300px;
      min-width: 300px;
      margin:0px auto; 
      @media (max-width: 300px) {
        width: 260px;
        min-width: 260px;
      }
  }

  .superMain {
    position: relative;
  }


  .subImdTxt {
    position: absolute;
    font-size: 9px;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 300px;
    text-align:left;

    @media (max-width: 300px) {
      width:260px
    }
    p{
        display:flex;
        span{
          white-space: nowrap;
        } 
        .apOne {
          margin-left: 4%;
          min-width: 18%;
          text-align: left;
        }
        .apTwo {
            margin-left: 16% ;
            text-align: center;
            min-width: 25%;
        }
        .apThree {
            margin-right: 3% !important;
            margin-left: 9% !important;
            text-align: center;
            min-width: 10%;
        }
        .apFour {
            margin-left: 0;
        }
    }
  } 
  
  
  p.en{
    .apOne {
      margin-left: 4%;
    }
    .apTwo {
      margin-left: 2% !important;
    }
    .apThree {
        margin-right: 2% !important;
        margin-left: 2% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 2% !important;
      }
    }
  }

  p.es,
  p.es-es{
    .apOne {
      margin-left: 4%;
    }
    .apTwo {
      margin-left: 2% !important;
    }
    .apThree {
      margin-right: 2% !important;
      margin-left: 6% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
        margin-right: 2% !important;
        margin-left: 6% !important;
      }
    }
  }
  p.th{
    .apOne {
      margin-left: 7%;
    }
    .apTwo {
      margin-left: 0% !important;
    }
    .apThree {
        margin-right: 2% !important;
        margin-left: 6% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 7%;
      }
      .apTwo {
        margin-left: 0% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 7% !important;
      }
    }
  }

  p.fr{
    .apOne {
      margin-left: 7%;
    }
    .apTwo {
      margin-left: 1% !important;
    }
    .apThree {
        margin-right: 1% !important;
        margin-left: 6% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 5%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 7% !important;
      }
    }
  }

  p.el{
    .apOne {
      margin-left: 5%;
    }
    .apTwo {
      margin-left: 1% !important;
    }
    .apThree {
        margin-right: 1% !important;
        margin-left: 1% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 5%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 7% !important;
      }
    }
  }
  p.ny{
    .apOne {
      margin-left: 2%;
    }
    .apTwo {
      margin-left: 2% !important;
    }
    .apThree {
        margin-right: 2% !important;
        margin-left: 4% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 2%;
      }
      .apTwo {
        margin-left: 1% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 0% !important;
      }
    }
  }
  p.lg{
    .apOne {
      margin-left: 4%;
    }
    .apTwo {
      margin-left: 2% !important;
    }
    .apThree {
        margin-right: 2% !important;
        margin-left: 5% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 3%;
      }
      .apTwo {
        margin-left: 4% !important;
      }
      .apThree {
          margin-right: 1% !important;
          margin-left: 2% !important;
      }
    }
  }
  p.rw{
    .apOne {
      margin-left: 4%;
    }
    .apTwo {
      margin-left: 2% !important;
    }
    .apThree {
        margin-right: 2% !important;
        margin-left: 5% !important;
    }
    
    @media (max-width: 300px) {
      .apOne {
        margin-left: 4%;
      }
      .apTwo {
        margin-left: 2% !important;
      }
      .apThree {
          margin-right: 2% !important;
          margin-left: 5% !important;
      }
    }
  }

`;