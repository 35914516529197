import { Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { RaisedButton } from "../../assets/styles/BaseStyle";

export const PopUp = styled(Modal)`
    [class~="modal-content"] {
        margin-top:20%;
        border-radius:20px;
    }
    @media (min-width:320px) and (max-width:425px) {
    [class~="modal-content"] {
        width:auto;
        margin:35% 13px;
    }
    }
`;


export const PopModalBody = styled(ModalBody)`
    div{
        margin: 15px 30px;
        font-size: 20px
    }
    p{
        margin: 0px 30px;
    }
    @media (min-width:320px) and (max-width:425px) {
    div{
        margin: 15px 15px;
        font-size: 20px
    }
    p{
        margin: 0px 15px;
    }
    }
`;

const TimerButton = styled(RaisedButton)`
  margin: 0 auto;
  border-radius: 20px;
  color: #fff;
  display: block;
  border: 0;
  padding: 0.375rem 0.75rem;
  outline: 0;
  min-width: 200px;
  margin: 15px auto 15px !important;

  ${({ disabled }) => disabled && `
    background: #DDE2E8;
    `}

  @media (max-width: 576px) {
    ${({ buttondesign }) => eval(buttondesign) ? `
    margin: 0px !important;
    min-width:125px;
    font-size:14px;
    ` : `
    min-width:200px
    `};
  }
`;

export const CancelButton = styled(TimerButton)`
  background: ${({ theme }) => theme.button.timer.navigation.background};
`

export const TimerFooter = styled(ModalFooter)`
  border: 0;
  padding:0 0 10px;
  justify-content: space-between;
  
  ${({ buttondesign }) => eval(buttondesign) ? `
  @media(max-width: 576px) {
    display: flex;
  }
  ` : `
  @media(max-width: 576px) {
    display: block
  }
  `}

`;
