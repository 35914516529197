import styled from 'styled-components';

export const TooltipContainer = styled.div`
  ${({pointer}) => pointer && `display: none;`}
  visibility: ${({visible}) => visible ? `visible` : 'hidden'};
  position: relative;
  margin-bottom: 16px;
`;

export const Tooltip = styled.div`
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
  height: 70px;
  text-align: center;
  background-color: #F0F3F7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  
`;

export const ToolTipValue = styled.div`
  position: relative;
  width: 100%;
`;

export const ToolTipSpan = styled.span`
  text-align: center;
  width: 100%;
  position: absolute;
  color: #333;
  transform: translate(-50%,-50%);
  opacity: ${({opacity}) => opacity}
`;

export const TooltipTick = styled.div`
  left: ${({ position }) => position}%;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  margin-left: -15px;
  margin-top: -1px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #F0F3F7;
`;