import styled from 'styled-components';

export const CenteredText  = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const InstructionImage = styled.div`
  margin: 0 auto;
    
  width: ${({width}) => width}%;
  
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
  }
  
  img {
    display:block;
   max-width: 100%;
   margin: 0 auto;
  }
`;

